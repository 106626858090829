var addedListener = [];

const EventBus = {
  $on(eventType, callback) {
    if (addedListener.indexOf(eventType) === -1) {
      addedListener.push(eventType);
      document.addEventListener(eventType, (e) => callback(e.detail));
    }
  },
  $dispatch(eventType, data) {
    const fn = new CustomEvent(eventType, { detail: data });
    document.dispatchEvent(fn);
  },
  $remove(eventType, callback) {    
    const index = addedListener.indexOf(eventType);
    addedListener.splice(index,1);
    document.removeEventListener(eventType, callback);
  },
};

export default EventBus;
