import { Box } from "@mui/material";
import { constant } from "../../config/constant";

const HomeContent = ({ children }) => {
  return (
    <Box overflow="auto">
      <Box paddingTop="18px" paddingX="32px">{children}</Box>
    </Box>
  );
};

export default HomeContent;
