import { grey } from "@mui/material/colors";

const PageSubTitle = ({ children, fontSize = "14px" }) => {
  const titleStyle = {
    fontSize: fontSize,
    padding: "2px 0",
    color: grey[600],
    fontWeight: 500,
  };
  return <div style={titleStyle}>{children}</div>;
};

export default PageSubTitle;
