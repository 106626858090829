import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CenterLayout from "../../layouts/CenterLayout";
import { httpErrorHandling, httpGet, httpPostFormData } from "../../services/http";
import "./LoginPage.scss";
import { Controller, useForm } from "react-hook-form";
import { SnackContext } from "../../components/Main/Main";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/actions/authAction";
import logo from "../../assets/images/logo-sample.jpg";
import text from "../../config/text";
import { setToken } from "../../services/auth";
import storage from "../../services/storage";
import EventBus from "../../common/EventBus";
import { grey } from "@mui/material/colors";

var packageJson = require('../../../package.json');

export default function LoginPage() {
  const snack = React.useContext(SnackContext);

  const [visible, setVisible] = React.useState(false);
  const handleVisible = () => {
    setVisible(!visible);
  };

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const user = useSelector((state) => state.auth);

  useEffect(() => {
    if (user.login === true) {
      navigate("/home");
    }
  }, [user]);

  const checkState = () => {};

  const submitLogin = async (data) => {
    try {
      setLoading(true);
      const result = await httpPostFormData("token", data, false);
      const token = result?.data?.access_token;
      if (token) {
        setToken(token);
        httpGet("user/me").then((res) => {
          storage.set("user", res.data);
          dispatch(login(res.data));
          EventBus.$dispatch("app.login", { login: true });
          snack.setSnack((prev) => ({
            ...prev,
            open: true,
            message: "Signed In",
            variant: "success",
          }));
          setLoading(false);
          reset();
          navigate("/dashboard");
        });
      } else {
        snack.setSnack((prev) => ({
          ...prev,
          open: true,
          variant: "warning",
          message: result?.detail || text.tryAgain,
        }));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      const err = httpErrorHandling(error);      
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        variant: "warning",
        message: err?.detail || text.tryAgain,
      }));
    }
  };

  return (
    <CenterLayout>
      <Box component="div" width="80%" maxWidth="480px">
        <Grid container>
          <Grid item xs={12}>
            {/* <img src={logo} className="logo"></img> */}
          </Grid>
          <Grid item xs={12}>
            <div className="login-container">
              <div className="login-header">
                <div className="login-title">Sign In</div>
                <div className="login-subtitle">
                  Welcome back! Please enter your details.
                </div>
              </div>
              <div className="login-body">
                <form onSubmit={handleSubmit(submitLogin)} id="loginForm">
                  <Box
                    component="div"
                    display="flex"
                    flexDirection="column"
                    gap="12px"
                  >
                    <FormControl fullWidth>
                      <InputLabel htmlFor="login-username">Username</InputLabel>
                      <Controller
                        name="username"
                        control={control}
                        render={({ field }) => (
                          <OutlinedInput
                            disabled={loading}
                            id="login-username"
                            label="Username"
                            variant="standard"
                            {...field}
                            {...register("username", { required: true })}
                            error={errors.username != null}
                          />
                        )}
                      />
                      {errors.username != null && (
                        <FormHelperText className="error-text">
                          {errors.username?.type === "required"
                            ? "Username is required"
                            : ""}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <Box component="div">
                      <FormControl fullWidth>
                        <InputLabel htmlFor="login-password">
                          Password
                        </InputLabel>
                        <Controller
                          name="password"
                          control={control}
                          render={({ field }) => (
                            <OutlinedInput
                              disabled={loading}
                              id="login-password"
                              fullWidth
                              label="Password"
                              variant="standard"
                              name="password"
                              type={!visible ? "password" : "text"}
                              {...field}
                              {...register("password", {
                                required: true,
                              })}
                              error={errors.password != null}
                              endAdornment={
                                <IconButton
                                  sx={{ color: "action.active", mt: 0.5 }}
                                  onClick={handleVisible}
                                >
                                  {!visible ? (
                                    <Icon>visibility</Icon>
                                  ) : (
                                    <Icon>visibility_off</Icon>
                                  )}
                                </IconButton>
                              }
                            />
                          )}
                        />
                        {errors.password != null && (
                          <FormHelperText className="error-text">
                            {errors.password?.type === "required"
                              ? "Password is required"
                              : ""}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Box>

                    <Button
                      color="primary"
                      form="loginForm"
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={loading}
                      // onClick={handleSubmit(submitLogin)}
                    >
                      {loading ? <CircularProgress size={26} /> : "Log In"}
                    </Button>
                  </Box>
                </form>
              </div>
              <Box display="flex" color={grey[600]} flexDirection="row" justifyContent="flex-end" fontSize="12px" fontWeight="600">
                Ver {packageJson.version}
              </Box>
            </div>
          </Grid>
        </Grid>
      </Box>
    </CenterLayout>
  );
}
