import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { minHeight } from "@mui/system";
import TopMenuBar from "../../components/TopMenuBar/TopMenuBar";
import { constant } from "../../config/constant";
import theme from "../../config/theme";

const HomeHeader = () => {
  return (
    <Box            
      height={constant.headerHeight}
      minHeight={constant.headerHeight}
      borderBottom="1px solid"
      borderColor={grey[200]}
      bgcolor={theme.palette.light.main}  
      width="100%"    
    >
      <TopMenuBar></TopMenuBar>
    </Box>
  );
};
export default HomeHeader;
