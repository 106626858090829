import React, { useState } from "react";
import Slide from "@mui/material/Slide";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import "./EmployeeOption.scss";
import { Controller, useForm } from "react-hook-form";
import {
  httpErrorHandling,
  httpGet,
  httpPost,
  httpPostFormData,
} from "../../services/http";
import { SnackContext } from "../Main/Main";
import text from "../../config/text";
import { useDispatch } from "react-redux";
import { loading, stoploading } from "../../store/actions/loadingAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const EmployeeOption = (props) => {
  const { onClose, selectedValue, open, id, param } = props;

  const handleClose = () => {
    reset();
    onClose(selectedValue);
  };

  const [maxWidth, setMaxWidth] = React.useState("sm");

  const [state, setState] = useState();

  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const snack = React.useContext(SnackContext);

  const roles = [
    { id: "admin", text: "Admin" },
    { id: "super_admin", text: "Superadmin" },
  ];

  const dispatch = useDispatch();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm({
    defaultValues: state,
  });

  const submit = async (data) => {
    try {
      dispatch(loading());
      delete data.mode;
      delete data.confirmpassword;
      const result = await httpPost("user/create", data);
      if (result && result.data.success) {
        snack.setSnack((prev) => ({
          ...prev,
          open: true,
          variant: "success",
          message: "User added",
        }));
        reset();
      } else {
        snack.setSnack((prev) => ({
          ...prev,
          open: true,
          variant: "warning",
          message: result?.detail || text.tryAgain,
        }));
      }
      dispatch(stoploading());
    } catch (err) {
      dispatch(stoploading());
      const error = httpErrorHandling(err);
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        variant: "warning",
        message: error?.detail || text.tryAgain,
      }));
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="dialog-slide"
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      fullWidth={true}
    >
      <DialogTitle className="modal-title">
        Update {param ? param.name : ''}
        <Box
          title="Close"
          position="absolute"
          top="20px"
          right="12px"
          sx={{ cursor: "pointer" }}
          onClick={handleClose}
        >
          <Icon>close</Icon>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box paddingTop={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="standard-basic"
                label="Search Zenoti Employee"
                variant="standard"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
                <Box height="240px" overflow="auto">

                </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="warning">
          Cancel
        </Button>
        <Button onClick={handleSubmit(submit)} autoFocus variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmployeeOption;
