import { Box } from "@mui/material";
import { constant } from "../../config/constant";
import HomeContent from "./HomeContent";
import HomeHeader from "./HomeHeader";
import HomeSidebar from "./HomeSidebar";

const HomeLayout = ({ children }) => {
  return (
    <>
      <Box width="100%" height="100vh" display="flex" flexDirection="row">
        <HomeSidebar></HomeSidebar>
        <Box
          sx={{
            left: {
              lg: constant.sideBarWidth,
              xs: constant.sideBarWidthSm,
            },
            transition: constant.sideBarTransition,
            width:"100%",
            paddingLeft: {
              lg: constant.sideBarWidth,
              xs: constant.sideBarWidthSm,        
            },
          }}
          display="flex"
          flexDirection="column"
        >
          <HomeHeader></HomeHeader>
          <HomeContent>{children}</HomeContent>
        </Box>
      </Box>
    </>
  );
};

export default HomeLayout;
