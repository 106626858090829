import { Box, Icon, ListItemIcon } from "@mui/material";
import { grey } from "@mui/material/colors";
import { color } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./SideNavigation.scss";

const SideNavigation = () => {
  const [open, setOpen] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const pages = [
    {
      title: "Dashboard",
      url: "/dashboard",
      role: "admin",
      icon: "home",
    },
    {
      title: "Clinics",
      icon: "assignment_outline",
      url: "/clinics",
      role: "admin",
    },
    {
      title: "Employees",
      role: "admin",
      icon: "people_outline",
      children: [
        { title: "Manage Employees", url: "/employees", role: "admin" },
        { title: "Review Employees", url: "/employees-review", role: "admin" },
      ],
    },
    {
      title: "Sync Now",
      url: "/syncnow",
      role: "admin",
      icon: "date_range_outline",
    },
    {
      title: "Sync History",
      role: "admin",
      icon: "sync_outline",
      url: "/synchistory",
      // children: [
      //   { title: "Current Queue", url: "/current-queue", role: "admin" },
      //   { title: "Completed Syncs", url: "/complete-sync", role: "admin" },
      // ],
    },
    {
      title: "Configuration",
      role: "admin",
      icon: "settings_outline",
      children: [
        { title: "Master Data", url: "/master-data", role: "admin" },
        { title: "Users", url: "/users", role: "admin" },
      ],
    },
  ];
  const iconUp = "keyboard_arrow_up";

  useEffect(() => {
    // console.log(location.pathname);
  });

  const openSubMenu = (index, level, url = "") => {
    if (level === 1) {
      if (pages[index].children) {
        if (open === index) {
          setOpen(null);
        } else {
          setOpen(index);
        }
      } else {
        navigate(url);
      }
    } else {
      navigate(url);
    }
  };

  return (
    <>
      <div className="side-nav-container">
        {pages.map((page, index) => {
          return (
            <div key={"mm-" + index.toString()}>
              <div
                onClick={() => openSubMenu(index, 1, page?.url)}
                className={
                  "side-nav-item " +
                  (open === index ? "open" : "close") +
                  (page.url && page.url === location.pathname ? " active " : "")
                }
              >
                <ListItemIcon>
                  <Icon>{page.icon}</Icon>
                </ListItemIcon>
                <Box whiteSpace="nowrap" fontSize="14px">
                  {page.title}
                </Box>

                {page.children && page.children.length ? (
                  <div className="side-nav-caret">
                    <Icon>{iconUp}</Icon>
                  </div>
                ) : null}
              </div>
              {page.children && page.children.length && index === open ? (
                <div className="side-nav-submenu-container">
                  {page.children.map((child, i) => {
                    return (
                      <div
                        className={
                          "side-nav-submenu-item " +
                          (child.url === location.pathname ? "active" : "")
                        }
                        key={"sm-" + i.toString()}
                        onClick={() => openSubMenu(i, 2, child?.url)}
                      >
                        <Box whiteSpace="nowrap" fontSize="14px">
                          {child.title}
                        </Box>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          );
        })}        
      </div>
    </>
  );
};

export default SideNavigation;
