import { Alert, Box, Button, Chip, Icon, IconButton } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import DateFormat from "../../../components/DateFormat";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { constant } from "../../../config/constant";
import "./SyncDetail.scss";
import { CopyBlock, monoBlue } from "react-code-blocks";
import SyncDetailJobSyncFailedApi from "./SyncDetailJobSyncFailedApi";
import SyncDetailFailedItems from "./SyncDetailFailedItems";
import dayjs from "dayjs";

const SyncDetail = ({ syncData, onBackButtonClick }) => {
  const [state, setState] = useState({
    status: "success",
  });

  const [failedApi, setFailedApi] = useState([]);
  const [parameter, setParameter] = useState({});

  useEffect(() => {}, []);

  const failedApiColumn = [
    {
      field: "date",
      headerName: "Date",
      minWidth: 120,
      flex: 1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      renderCell: (param) => {
        return (
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            <DateFormat>{param.row.date}</DateFormat>
          </Box>
        );
      },
    },
    {
      field: "startTime",
      headerName: "Start Time",
      minWidth: 120,
      flex: 1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
    },
    {
      field: "endTime",
      headerName: "End Time",
      minWidth: 120,
      flex: 1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      flex: 1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      renderCell: (param) => {
        return (
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            {param.row.status === "Working" ? (
              <Chip
                icon={<Icon>checkmark</Icon>}
                label={param.row.status}
                color="success"
                variant="contained"
                size="small"
              />
            ) : (
              <Chip
                icon={<Icon>close</Icon>}
                label={param.row.status}
                color="warning"
                variant="contained"
                size="small"
              />
            )}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {}, []);

  const noRows = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Box display="flex" justifyContent="center" zIndex={99}>
        <h4 style={{ color: grey[600] }}>No Result Found</h4>
        <IconButton title="Reload">
          <Icon>refresh</Icon>
        </IconButton>
      </Box>
    </Box>
  );

  const jsonFormat = (data) => {
    try {
      let str = data
        .replaceAll("'", '"')
        .replaceAll("datetime.datetime", "")
        .replaceAll("datetime.date", "")
        .replaceAll(/["'()]/g, '"');        
      const json = JSON.parse(str);
      if (json["start_date"] && json["end_date"]) {
        const start_date = json["start_date"].split(",");
        json["start_date"] = dayjs(
          Date(start_date[0], start_date[1], start_date[2])
        ).format("YYYY-MM-DD");
        const end_date = json["end_date"].split(",");
        json["end_date"] = dayjs(
          Date(end_date[0], end_date[1], end_date[2])
        ).format("YYYY-MM-DD");
      }      
      return JSON.stringify(json, null, "\t");
    } catch (e) {
      console.log(e);
      return data;
    }
  };

  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      gap={2}
      className="completed-sync"
      margin="0 0 24px 0"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <PageTitle>Details</PageTitle>
        <Button
          onClick={() => onBackButtonClick()}
          size="small"
          sx={{ textTransform: "capitalize" }}
          color="grey"
          startIcon={<Icon>arrow_back_ios</Icon>}
        >
          Back to list
        </Button>
      </Box>
      <Box>
        {state.status === "success" ? (
          <Alert severity="success">
            Sync was completed successfuly on {syncData.dateJobFinished}
          </Alert>
        ) : (
          <Alert severity="error">
            Sync failed to run on {syncData.dateJobStarted}.
          </Alert>
        )}
      </Box>
      <Box
        border={"1px solid" + grey[200]}
        padding="24px"
        display="flex"
        flexDirection="row"
      >
        <Box flexBasis="50%">
          <table className="table-detail">
            <tbody>
              <tr>
                <td>Job Type</td>
                <td>{syncData.jobType}</td>
              </tr>
              <tr>
                <td>Date</td>
                <td>{syncData.dateJobStarted}</td>
              </tr>
              <tr>
                <td>Trigger Type</td>
                <td>{syncData.triggerType}</td>
              </tr>
            </tbody>
          </table>
        </Box>
        <Box flexBasis="50%">
          <table className="table-detail">
            <tbody>
              <tr>
                <td>Status</td>
                <td>{syncData.status}</td>
              </tr>
              <tr>
                <td>Duration</td>
                <td>{syncData.duration} minute(s)</td>
              </tr>
              <tr>
                <td>Run By</td>
                <td>{syncData.by}</td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        <PageTitle>Parameter</PageTitle>
        <Box marginTop="16px">
          <CopyBlock
            text={jsonFormat(syncData.parameter)}
            language="json"
            showLineNumbers={true}
            wrapLongLines={true}
            theme={monoBlue}
            codeBlock={true}
            customStyle={{
              borderRadius: "8px",
              fontSize: "0.9rem",
            }}
          />
        </Box>
      </Box>
      <SyncDetailFailedItems jobId={syncData.id}></SyncDetailFailedItems>
      <SyncDetailJobSyncFailedApi
        jobId={syncData.id}
      ></SyncDetailJobSyncFailedApi>
    </Box>
  );
};

export default SyncDetail;
