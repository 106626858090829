import {
  alpha,
  Box,
  Button,
  Chip,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import PageTitle from "../../components/PageTitle/PageTitle";
import { DataGrid } from "@mui/x-data-grid";
import { httpErrorHandling, httpPost } from "../../services/http";
import React, { useCallback, useEffect, useState } from "react";
import "./ClinicPage.scss";
import UserForm from "../../components/UserForm/UserForm";
import { useDispatch, useSelector } from "react-redux";
import pagination from "../../services/pagination";
import { PromptContext, SnackContext } from "../../components/Main/Main";
import text from "../../config/text";
import datetime from "../../services/datetime";
import { green, grey } from "@mui/material/colors";
import debounce from "lodash.debounce";
import FilterOption from "../../components/FilterOption/FilterOption";
import { constant } from "../../config/constant";

const ClinicPage = () => {
  const columns = [
    {
      field: "__check__",
      headerClassName: "table-data-header table-data-header-checkbox",
      width: 50,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Clinic Name",
      minWidth: 120,
      flex: 1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      renderCell: (param) => {
        return (
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            {param.row.syncEnabled === true ? (
              <Icon
                color="success"
                title="Sync Enabled"
                sx={{ fontSize: "20px" }}
              >
                play_circle_outline
              </Icon>
            ) : (
              <Icon
                title="Sync Disabled"
                sx={{ fontSize: "20px" }}
                color="warning"
              >
                pause_circle_outline
              </Icon>
            )}
            <Box>{param.row.name}</Box>
          </Box>
        );
      },
    },
    {
      field: "ledgerCode",
      headerName: "Ledger Code",
      minWidth: 80,
      flex: 1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
    },
    /* {
      field: "dayforceClinicId",
      headerName: "Dayforce ID",
      minWidth: 120,
      sortable: false,
      flex: 1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
    },
    {
      field: "zenotiClinicId",
      headerName: "Zenoti ID",
      minWidth: 120,
      sortable: false,
      flex: 1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
    }, */
    {
      field: "lastSynced",
      headerName: "Last Modified",
      minWidth: 80,
      flex: 1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 80,
      flex: 1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      renderCell: (param) => {
        return statusRow(param.row.status);
      },
    },

    /* {
      field: "option",
      headerName: "Sync",
      width: 72,
      flex: 0,
      headerClassName: "table-data-header",
      sortable: false,
      filter: false,
      disableColumnMenu: true,
      renderCell: (param) => {
        return (
          <Box>
            <Switch
              size="small"
              checked={param.row.syncEnabled}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(row) => switchSync(param)}
            />
          </Box>
        );
      },
    }, */
  ];

  const [rows, setRows] = useState([]);
  const [role, setRole] = useState(false);
  const user = useSelector((state) => state.auth);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCountState, setRowCountState] = React.useState(10);
  const [error, setError] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const snack = React.useContext(SnackContext);
  const dispatch = useDispatch();
  const [loadingData, setLoadingData] = useState(false);
  const [sortModel, setSortModel] = React.useState([]);
  const [sorting, setSorting] = useState();
  const [searchField, setSearchField] = useState("");
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("All");
  const statuses = [
    { id: "Linked", text: "Linked", icon: "check" },
    { id: "Closed", text: "Closed", icon: "close" },
    {
      id: "MissingLedgerCode",
      text: "Missing Ledger Code",
      icon: "error_outline",
    },
    { id: "NoMatchFound", text: "No Match Found", icon: "error_outline" },
  ];

  const statusOptions = [
    { id: "All", text: "All", icon: "" },
    { id: "Linked", text: "Linked", icon: "check" },
    { id: "Error", text: "Linking Error", icon: "error_outline" },
    { id: "Closed", text: "Closed", icon: "close" },
  ];

  const [statusObject, setStatusObject] = useState({});
  const [selectedRow, setSelectedRow] = useState([]);

  const [loading, setLoading] = useState({ sync: false });

  useEffect(() => {
    getData();
  }, [page, sorting]);

  useEffect(() => {
    if (page !== 0) setPage(0);
    else getData();
  }, [pageSize, status, searchField]);

  useEffect(() => {
    if (user.role === "super_admin") {
      setRole(true);
    }
    const obj = {};
    statuses.forEach((status) => {
      obj[status.id] = { text: status.text, icon: status.icon };
    });
    setStatusObject(obj);
  }, []);

  const getData = async () => {
    try {
      setError(false);
      setLoadingData(true);

      const filters = {};
      if (searchField) {
        if (searchField.match(/^[0-9]*$/gi)) {
          filters.ledgerCode = searchField;
        } else {
          filters.clinicName = searchField;
        }
      }

      if (status && status !== "All") {
        filters.status = status;
      }

      const body = {
        ...pagination.paginate(page, pageSize),
        filters: filters,
        order_by: sorting,
      };
      const res = await httpPost("clinic/list", body);
      const temp = [];
      if (res && res.data) {
        console.log(res.data.data)
        res.data.data.forEach((el, i) => {          
          temp.push({
            clinicId: el.clinicId,
            name: el.name,
            ledgerCode: el.ledgerCode,
            dayforceClinicId: el.dayforceClinicId,
            zenotiClinicId: el.zenotiClinicId,
            status: el.status,
            lastSynced: datetime.utcToLocal(el.lastSynced),
            cellClassName: "table-data-cell",
            disableSelectionOnClick: true,
            syncEnabled: el.syncEnabled,
          });
        });
      }
      setRows(temp);
      setRowCountState(res.data.total);
      setLoadingData(false);
    } catch (err) {
      setError(true);
      setLoadingData(false);
      const error = httpErrorHandling(err);
    }
  };

  /*
    clinic_name_asc = "Clinic"
    clinic_name_desc = "!Clinic"
    ledger_code_asc = "LedgerCode"
    ledger_code_desc = "!LedgerCode"
    last_synced_asc = "LastSynced"
    last_synced_desc = "!LastSynced"
    status_asc = "Status"
    status_desc = "!Status"
  */

  const handleSortModelChange = async (newSort) => {
    await setSortModel(newSort);
    if (newSort?.length) {
      let sort = "";
      switch (newSort[0].field) {
        case "name":
          sort = "Clinic";
          break;
        case "ledgerCode":
          sort = "LedgerCode";
          break;
        case "lastSynced":
          sort = "LastSynced";
          break;
        case "status":
          sort = "Status";
          break;
        default:
          setSortModel([]);
      }
      if (newSort[0].sort === "desc") {
        sort = "!" + sort;
      }
      setSorting(sort);
    }
  };

  const handleSearch = useCallback((el) => {
    setSearchText(el.target.value);
    searchCb(el);
  });

  const searchCb = useCallback(
    debounce((el) => {
      setSearchField(el.target.value);
    }, 700)
  );

  const clearSearch = () => {
    setSearchField("");
    setSearchText("");
  };

  const [propertyDialog, setPropertyDialog] = useState(false);

  const handleCloseDialog = () => {
    setSelectedId(null);
    setPropertyDialog(false);
    getData();
  };
  const handleOpenDialog = () => {
    setPropertyDialog(true);
  };

  const prompt = React.useContext(PromptContext);

  const changeStatus = (status) => {
    setStatus(status);
  };

  const selectRow = (ids) => {
    const selectedIDs = new Set(ids);
    const selectedRowData = rows.filter((row) => selectedIDs.has(row.clinicId));
    setSelectedRow(selectedRowData);
  };

  const switchSync = async (param) => {
    if (!selectedRow.length) {
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        variant: "warning",
        message: "Please select Clinic",
      }));
      return;
    }
    let ans = await prompt.openPrompt(
      "Confirm",
      `Do you want to ${
        param ? "Turn On " : "Turn Off "
      } sync for these clinics?`
    );
    if (!ans) return;
    const payload = { syncEnabled: param, clinicIds: [] };

    selectedRow.forEach((row) => {
      payload.clinicIds.push(row);
    });

    setLoadingData(true);
    try {
      const result = await httpPost("clinic/sync", payload);
      setLoadingData(false);
      if (result && result.data.success) {
        snack.setSnack((prev) => ({
          ...prev,
          open: true,
          variant: "success",
          message: "Updated",
        }));
        setSelectedRow([]);
        getData();
        // param.api.updateRows([
        //   { ...param.row, id: param.id, syncEnabled: !param.row.syncEnabled },
        // ]);
      } else {
        snack.setSnack((prev) => ({
          ...prev,
          open: true,
          variant: "warning",
          message: result?.detail || text.tryAgain,
        }));
      }
    } catch (err) {
      setLoadingData(false);
      console.log(err);
      const e = httpErrorHandling(err);
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        variant: "warning",
        message: text.tryAgain,
      }));
    }
  };

  const noRows = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Box display="flex" justifyContent="center" zIndex={99}>
        <h4 style={{ color: grey[600] }}>No Result Found</h4>
        <IconButton onClick={() => getData()} title="Reload">
          <Icon>refresh</Icon>
        </IconButton>
      </Box>
    </Box>
  );

  const statusRow = (status) => {
    return (
      <Stack direction="row">
        <Chip
          sx={{
            display: status === "Linked" ? "flex" : "none",
            flexDirection: "row",
            alignContent: "center",
            fontSize: "11px",
            color: green[800],
            bgcolor: alpha(green[100], 0.5),
          }}
          icon={<Icon color={green[800]}>{statusObject[status].icon}</Icon>}
          label={statusObject[status].text}
          variant="contained"
          size="small"
        ></Chip>
        <Chip
          sx={{
            display: status !== "Linked" ? "flex" : "none",
            flexDirection: "row",
            alignContent: "center",
            fontSize: "11px",
            color: grey[700],
            bgcolor: alpha(grey[100], 0.9),
          }}
          icon={<Icon color={grey[700]}>{statusObject[status].icon}</Icon>}
          label={statusObject[status].text}
          variant="contained"
          size="small"
        />
      </Stack>
    );
  };

  const syncNow = async () => {
    try {
      setLoading((l) => ({ ...l, sync: true }));
      const res = await httpPost("lambda/clinic/sync");
      console.log(res);
      if (res.status === 200) {
        snack.setSnack((prev) => ({
          ...prev,
          open: true,
          message: "Clinic sync has been started successfully",
          variant: "success",
        }));
      } else {
        throw "not success";
      }
      setLoading((l) => ({ ...l, sync: false }));
    } catch (e) {
      httpErrorHandling(e);
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        message: "Please try again.",
        variant: "warning",
      }));
      setLoading((l) => ({ ...l, sync: false }));
    }
  };

  return (
    <Box component="div" display="flex" flexDirection="column" gap={2}>
      <PageTitle>Manage Clinic</PageTitle>
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        width="100%"
        alignContent="center"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="row" gap={1}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel htmlFor="clinic-filter-search">Search</InputLabel>
            <OutlinedInput
              id="clinic-filter-search"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    disabled={searchField.length <= 0}
                    onClick={clearSearch}
                  >
                    <Icon
                      sx={{
                        color:
                          searchField.length <= 0 ? "transparent" : "inherit",
                      }}
                    >
                      close
                    </Icon>
                  </IconButton>
                </InputAdornment>
              }
              onChange={handleSearch}
              label="Search"
              value={searchText}
            />
          </FormControl>
        </Box>
        <Box></Box>
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          gap={1}
        >
          {/* <FormControl size="small" sx={{ width: 216 }}>
            <InputLabel id="clinic-filter-status">Status</InputLabel>
            <Select
              labelId="clinic-filter-status"
              id="clinic-filter-status"
              label="Status"
              variant="outlined"
              autoWidth
              value={status}
              onChange={changeStatus}
            >
              {Object.keys(statuses).map((key) => {
                return (
                  <MenuItem key={key} value={key}>
                    {statuses[key]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>           */}
          <FilterOption
            selected={status}
            items={statusOptions}
            onSelectItem={changeStatus}
            label="Status"
            disabled={loadingData}
          ></FilterOption>
          <Button
            startIcon={<Icon sx={{ fontSize: "14px" }}>sync</Icon>}
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            color="grey"
            size="small"
            onClick={() => switchSync(true)}
            disabled={loadingData}
          >
            Turn On
          </Button>
          <Button
            startIcon={<Icon sx={{ fontSize: "14px" }}>link</Icon>}
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            color="grey"
            size="small"
            onClick={() => switchSync(false)}
            disabled={loadingData}
          >
            Turn Off
          </Button>
          <Button
            startIcon={
              <Icon
                className={loading.sync ? "spining" : ""}
                sx={{ fontSize: "14px" }}
              >
                sync
              </Icon>
            }
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            color="grey"
            size="small"
            onClick={syncNow}
            disabled={loadingData || user.role !== "super_admin" || loading.sync}
          >
            Refresh
          </Button>
        </Box>
      </Box>
      <div className="table-data-container">
        <Box style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              getRowId={(row) => row.clinicId}
              rows={rows}
              columns={columns}
              loading={loadingData}
              rowHeight={constant.tableRowHeight}
              headerHeight={constant.tableHeaderHeight}
              disableSelectionOnClick
              page={page}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50]}
              pagination
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowCount={rowCountState}
              sortingMode="server"
              sortingOrder={["desc", "asc"]}
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              disableColumnFilter
              components={{
                NoResultsOverlay: noRows,
              }}
              checkboxSelection={true}
              onSelectionModelChange={setSelectedRow}
              selectionModel={selectedRow}
            />
          </div>
        </Box>
      </div>
      <UserForm
        open={propertyDialog}
        onClose={handleCloseDialog}
        id={selectedId}
      ></UserForm>
    </Box>
  );
};

export default ClinicPage;
