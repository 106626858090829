const initialState = {
  loaded: false,
  loggedIn: false,
};

const appReducer = (state = initialState, action) => {  
  const { type, payload } = action;
  switch (type) {
    case "loaded":
      return Object.assign(state, {
        loaded: true,
      });
    case "loggedIn":
      return Object.assign(state, {
        loggedIn: payload,
      });
    default:
      return state;
  }
};
export default appReducer;
