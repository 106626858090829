import {
  alpha,
  Box,
  Button,
  Chip,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import "./InProgress.scss";
import { useDispatch, useSelector } from "react-redux";
import { green, grey } from "@mui/material/colors";
import debounce from "lodash.debounce";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { httpErrorHandling, httpPost } from "../../../services/http";
import { PromptContext, SnackContext } from "../../../components/Main/Main";
import text from "../../../config/text";
import datetime from "../../../services/datetime";
import pagination from "../../../services/pagination";
import FilterOption from "../../../components/FilterOption/FilterOption";
import { constant } from "../../../config/constant";
import dayjs from "dayjs";

const InProgress = ({ onRowClick }) => {
  const columns = [
    /* {
      field: "__check__",
      headerClassName: "table-data-header",
      width: 50,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      filter: false,
    }, */
    {
      field: "id",
      headerName: "Job ID",
      minWidth: 80,
      flex: 1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      sortable: true,
      filter: false,
    },
    {
      field: "jobType",
      headerName: "Type",
      minWidth: 120,
      flex: 1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      filter: false,
      renderCell: (param) => {
        return (
          <Box className="capitalize" display="flex" flexDirection="row" alignItems="center" gap={1}>
            {param.row.jobTypeName}
          </Box>
        );
      },
    },
    {
      field: "dateJobStarted",
      headerName: "Date Started",
      minWidth: 80,
      flex: 1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      sortable: true,
      filter: false,
    },
    /* {
      field: "dateJobFinished",
      headerName: "Date Finished",
      minWidth: 80,
      flex: 1.5,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      sortable: false,
      filter: false,
    },

    {
      field: "status",
      headerName: "Duration (min)",
      minWidth: 120,
      flex: 1.1,
      headerClassName: "table-data-header table-data-header-checkbox",
      disableColumnMenu: true,
      sortable: false,
      filter: false,
      alignItems: "end",
      renderCell: (param) => {
        return (
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
          >
            <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {param.row.duration}
            </Box>
          </Box>
        );
      },
    }, */
    {
      field: "by",
      headerName: "By",
      minWidth: 80,
      flex: 1.5,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      sortable: false,
      filter: false,
    },
    /*  {
      field: "status",
      headerName: "Sync",
      minWidth: 80,
      flex: 1,
      sortable: false,
      filter: false,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      renderCell: (param) => {
        return statusRow(param.row.status);
      },
    }, */
  ];

  const [rows, setRows] = useState([]);
  const [role, setRole] = useState(false);
  const user = useSelector((state) => state.auth);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCountState, setRowCountState] = React.useState(10);
  const [error, setError] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const snack = React.useContext(SnackContext);
  const dispatch = useDispatch();
  const [loadingData, setLoadingData] = useState(false);
  const [sortModel, setSortModel] = React.useState([
    { field: "name", sort: "asc" },
  ]);
  const [sorting, setSorting] = useState("!Date");
  const [searchField, setSearchField] = useState("");
  const [searchText, setSearchText] = useState("");
  const statuses = [
    { id: "RUNNING", text: "RUNNING", icon: "refresh" },
    { id: "COMPLETED", text: "COMPLETED", icon: "check" },
  ];
  const [statusObject, setStatusObject] = useState({});
  const [selectedRow, setSelectedRow] = useState([]);

  const [clinicId, setClinicId] = useState([]);

  const prompt = React.useContext(PromptContext);

  const [status, setStatus] = useState("All");

  const statusOptions = [
    { id: "All", text: "All", icon: "" },
    { id: "Success", text: "Success", icon: "check" },
    { id: "Failed", text: "Failed", icon: "close" },
  ];

  useEffect(() => {
    getData();
  }, [page, searchField, sorting, status]);

  useEffect(() => {
    if (page != 0) setPage(0);
    else getData();
  }, [pageSize]);

  useEffect(() => {
    if (user.role === "super_admin") {
      setRole(true);
    }
    const obj = {};
    statuses.forEach((status) => {
      obj[status.id] = { text: status.text, icon: status.icon };
    });
    setStatusObject(obj);
  }, []);

  const getData = async () => {
    try {
      setError(false);
      setLoadingData(true);

      const filters = {};
      if (searchField) {
        filters.name = searchField;
      }

      filters.status = "RUNNING";

      if (clinicId.length) {
        filters.clinics = clinicId;
      }

      const body = {
        ...pagination.paginate(page, pageSize),
        filters: filters,
        order_by: sorting,
      };
      const res = await httpPost("sync/list", body);
      const temp = [];
      if (res && res.data) {
        res.data.data.forEach((el, i) => {
          temp.push({
            index: i,
            id: el.id,
            status: el.status,
            triggerType: el.triggerType,
            by: el.by,
            jobType: el.jobType,
            jobTypeName: el.jobType.replaceAll("_", " ").toLowerCase(),
            dateJobStarted: datetime.utcToLocal(el.dateJobStarted),
            dateJobFinished: datetime.utcToLocal(el.dateJobFinished),
            duration: getDuration(el.dateJobFinished, el.dateJobStarted),
            cellClassName: "table-data-cell",
            disableSelectionOnClick: true,
            syncEnabled: el.syncEnabled,
            zenotiEmployeeName: el.zenotiEmployeeName,
          });
        });
      }
      setRows(temp);
      setRowCountState(res.data.total);
      setLoadingData(false);
    } catch (err) {
      setError(true);
      setLoadingData(false);
      const error = httpErrorHandling(err);
    }
  };

  const getDuration = (time1, time2) => {
    return dayjs(time1).diff(time2, "minutes");
  };

  const handleSortModelChange = async (newSort) => {
    await setSortModel(newSort);
    if (newSort?.length) {
      let sort = "";
      switch (newSort[0].field) {
        case "dateJobStarted":
          sort = "Date";
          break;
        case "jobType":
          sort = "Type";
          break;        
        default:
          sort = "dateJobStarted";
          setSortModel([{ field: "dateJobStarted", sort: "desc" }]);
      }
      if (newSort[0].sort === "desc") {
        sort = "!" + sort;
      }
      setSorting(sort);
    }
  };

  const handleSearch = useCallback((el) => {
    setSearchText(el.target.value);
    searchCb(el);
  });

  const searchCb = useCallback(
    debounce((el) => {
      setSearchField(el.target.value);
    }, 700)
  );

  const clearSearch = () => {
    setSearchField("");
    setSearchText("");
  };

  const confirmLink = async (param) => {
    const payload = { status: "Linked", employeeIds: [] };
    console.log(param);
    if (!selectedRow.length) {
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        variant: "warning",
        message: "Please select Employee",
      }));
      return;
    }
    selectedRow.forEach((row) => {
      payload.employeeIds.push(row);
    });

    setLoadingData(true);
    try {
      const result = await httpPost("employee/linking", payload);
      setLoadingData(false);
      if (result && result.data.success) {
        snack.setSnack((prev) => ({
          ...prev,
          open: true,
          variant: "success",
          message: "Updated",
        }));
        setSelectedRow([]);
        getData();
        // param.api.updateRows([
        //   { ...param.row, id: param.id, syncEnabled: !param.row.syncEnabled },
        // ]);
      } else {
        snack.setSnack((prev) => ({
          ...prev,
          open: true,
          variant: "warning",
          message: result?.detail || text.tryAgain,
        }));
      }
    } catch (err) {
      setLoadingData(false);
      console.log(err);
      const e = httpErrorHandling(err);
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        variant: "warning",
        message: text.tryAgain,
      }));
    }
  };

  const viewAll = () => {
    if (status === "MatchFound") {
      setStatus("All");
    } else {
      setStatus("MatchFound");
    }
  };

  const noResults = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Box display="flex" justifyContent="center" zIndex={99}>
        <h4 style={{ color: grey[600] }}>No Result Found</h4>
        <IconButton onClick={() => getData()} title="Reload">
          <Icon>refresh</Icon>
        </IconButton>
      </Box>
    </Box>
  );

  const noRows = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Box display="flex" justifyContent="center" zIndex={99}>
        <h4 style={{ color: grey[600] }}>There is no job in progress</h4>       
      </Box>
    </Box>
  );

  

  const statusRow = (status) => {
    return (
      <Stack direction="row">
        <Chip
          sx={{
            display: status === "Linked" ? "flex" : "none",
            flexDirection: "row",
            alignContent: "center",
            fontSize: "11px",
            color: green[800],
            bgcolor: alpha(green[100], 0.5),
          }}
          icon={<Icon color={green[800]}>{statusObject[status].icon}</Icon>}
          label={statusObject[status].text}
          variant="contained"
          size="small"
        ></Chip>
        <Chip
          sx={{
            display: status !== "Linked" ? "flex" : "none",
            flexDirection: "row",
            alignContent: "center",
            fontSize: "11px",
            color: grey[700],
            bgcolor: alpha(grey[100], 0.9),
          }}
          icon={<Icon color={grey[700]}>{statusObject[status].icon}</Icon>}
          label={statusObject[status].text}
          variant="contained"
          size="small"
        />
      </Stack>
    );
  };

  const changeStatus = (status) => {
    setStatus(status);
  };

  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      gap={2}
      className="in-progress"
    >
      <PageTitle>In Progress</PageTitle>
      {/* <Box
        component="div"
        display="flex"
        flexDirection="row"
        width="100%"
        alignContent="center"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="row" gap={1}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel htmlFor="clinic-filter-search">Search</InputLabel>
            <OutlinedInput
              id="inprogress-filter-search"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    disabled={searchField.length <= 0}
                    onClick={clearSearch}
                  >
                    <Icon
                      sx={{
                        color:
                          searchField.length <= 0 ? "transparent" : "inherit",
                      }}
                    >
                      close
                    </Icon>
                  </IconButton>
                </InputAdornment>
              }
              onChange={handleSearch}
              label="Search"
              value={searchText}
            />
          </FormControl>
        </Box>
        <Box></Box>
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          gap={1}
        >
          <FilterOption
            selected={status}
            items={statusOptions}
            onSelectItem={changeStatus}
            label="Status"
            disabled={loadingData}
          ></FilterOption>
          <Button
            //   startIcon={<Icon sx={{ fontSize: "14px" }}>save</Icon>}
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            color="primary"
            size="small"
            onClick={confirmLink}
            disabled={loadingData}
          >
            Save
          </Button>
        </Box>
      </Box> */}
      <div className="table-data-container">
        <Box style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              getRowId={(row) => row.id}
              rows={rows}
              columns={columns}
              loading={loadingData}
              rowHeight={constant.tableRowHeight}
              headerHeight={constant.tableHeaderHeight}
              disableSelectionOnClick
              page={page}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50]}
              pagination
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowCount={rowCountState}
              sortingMode="server"
              sortingOrder={["desc", "asc"]}
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              disableColumnFilter
              components={{
                NoResultsOverlay: noResults,
                NoRowsOverlay: noRows,
              }}
              // checkboxSelection
              // onSelectionModelChange={setSelectedRow}
              // selectionModel={selectedRow}
              onRowClick={(data) => {
                console.log(data.row);
                // onRowClick(data.row);
              }}
              
            />
          </div>
        </Box>
      </div>
    </Box>
  );
};

export default InProgress;
