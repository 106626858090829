import authReducer from './reducer/authReducer'
import { combineReducers } from 'redux'
import loadingReducer from './reducer/loadingReducer'
import appReducer from './reducer/appReducer'

const allReducers = combineReducers({
  auth: authReducer,
  loading: loadingReducer,
  app: appReducer,
})
export default allReducers
