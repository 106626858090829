import React from "react";
import Slide from "@mui/material/Slide";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  useMediaQuery,
} from "@mui/material";

import "./SyncFailedItemDetail.scss";
import { CopyBlock, monoBlue } from "react-code-blocks";
import DateFormat from "../../../components/DateFormat";
import { useEffect } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function SyncFailedItemDetail(props) {
  const { onClose, selectedValue, open, itemDetail } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const [maxWidth, setMaxWidth] = React.useState("lg");

  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const jsonFormat = (data) => {
    try {
      let str = data
        .replaceAll(/[ \(\)]+/g, "")
        .replaceAll("\\n", "")
        .replaceAll("'", '"')
        .replaceAll("False", "false")
        .replaceAll("True", "true")
        .replaceAll("None", null);
      const json = JSON.parse(str);
      return JSON.stringify(json, null, "\t");
    } catch (e) {
      console.log(e);
      return data;
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="dialog-slide"
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      fullWidth={true}
    >
      <DialogTitle className="modal-title">
        Failed Item Detail
        <Box
          title="Close"
          position="absolute"
          top="20px"
          right="12px"
          sx={{ cursor: "pointer" }}
          onClick={handleClose}
        >
          <Icon>close</Icon>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Box height="64vh" overflow="auto" width="100%">
          <Box padding="12px 24px">
            {itemDetail ? (
              <div>
                <table className="table-info">
                  <tbody>
                    <tr>
                      <td>Job ID Code</td>
                      <td>{itemDetail?.jobId}</td>
                    </tr>
                    <tr>
                      <td>Item</td>
                      <td>{itemDetail?.item}</td>
                    </tr>
                    <tr>
                      <td>Error Message</td>
                      <td>{itemDetail?.errorMessage}</td>
                    </tr>
                    <tr>
                        <td>Change Log</td>
                        <td>
                        <CopyBlock
                          text={jsonFormat(itemDetail?.changeLog)}
                          language="json"
                          showLineNumbers={true}
                          wrapLines={true}
                          theme={monoBlue}
                          codeBlock
                          customStyle={{                                                        
                            borderRadius: '8px',                            
                            fontSize: '0.85rem',                              
                          }}
                        />
                        </td>
                    </tr>
                  </tbody>
                </table>                
              </div>
            ) : null}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus color="dark">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SyncFailedItemDetail;
