import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Icon,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PromptContext, SnackContext } from "../../components/Main/Main";
import PageTitle from "../../components/PageTitle/PageTitle";
import { httpErrorHandling, httpPost } from "../../services/http";
import { stoploading, loading } from "../../store/actions/loadingAction";

const color = "#F1F8FF";
const icon = <Icon>check_box_outline_blank</Icon>;
const checkedIcon = <Icon>check_box_outline</Icon>;

const SyncNow = () => {
  const snackbar = useContext(SnackContext);
  const prompt = useContext(PromptContext);

  const [form, setForm] = useState({
    clinics: [],
    employees: [],
    from: null,
    to: null,
  });

  const [valid, setValid] = useState({
    clinics: false,
    employees: false,
    from: false,
    to: false,
  });

  const [clinics, setClinics] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [pageLoading, setPageLoading] = useState({
    clinics: false,
    employees: false,
    page: false,
  });

  const [latestDate, setLatestDate] = useState("");
  const [minDate, setMinDate] = useState("");

  const maxDate = dayjs().add(6, "months");

  useEffect(() => {
    getClinics();
    // getEmployees();
    setMinDate(dayjs());
  }, []);

  useEffect(() => {
    if (form.from) {
      setMaxDate();
    }
  }, [form]);

  const [errors, setErrors] = useState({ clinics: false, employees: false });
  const dispatch = useDispatch();

  // up to here

  const getClinics = async () => {
    try {
      setErrors((state) => ({ ...state, clinics: false }));
      setPageLoading((state) => ({ ...state, clinics: true }));
      const res = await httpPost("clinic/option");
      if (res.data) {
        const options = res.data;
        options.unshift({
          id: "all",
          name: "Select All",
        });
        setClinics(
          res.data.map((d) => ({
            id: d.id,
            name: d.name,
            dayforceClinicId: d.dayforce_clinic_id,
          }))
        );
      }
      setPageLoading((state) => ({ ...state, clinics: false }));
    } catch (e) {
      console.log(e);
      httpErrorHandling(e);
      setErrors((state) => ({ ...state, clinics: true }));
      setPageLoading((state) => ({ ...state, clinics: false }));
    }
  };

  const getEmployees = async () => {
    const payload = { keyword: "" };
    try {
      setErrors((state) => ({ ...state, employees: false }));
      setPageLoading((state) => ({ ...state, employees: true }));

      const res = await httpPost("employee/dayforce/option", payload);
      if (res.data) {
        const options = res.data;
        options.unshift({
          id: "all",
          name: "Select All",
        });
        setEmployees(
          options.map((d) => ({
            id: d.id,
            name: d.name,
            dayforceEmployeeId: d.dayforce_employee_id,
          }))
        );
      }
      setPageLoading((state) => ({
        ...state.loading,
        employees: false,
      }));
    } catch (e) {
      httpErrorHandling(e);
      setErrors((state) => ({ ...state, employees: true }));
      setPageLoading((state) => ({ ...state, employees: false }));
    }
  };

  const selectClinics = (value) => {
    var clinics_temp = [];
    if (form.clinics.findIndex((c) => c.id === "all") !== -1) {
      if (value.findIndex((c) => c.id === "all") === -1) {
        clinics_temp = value;
      } else {
        if (value.length > 1) {
          clinics_temp = value.filter((v) => v.id !== "all");
        }
      }
    } else {
      if (value.findIndex((c) => c.id === "all") !== -1) {
        clinics_temp = value.filter((v) => v.id === "all");
      } else {
        clinics_temp = value;
      }
    }
    if (clinics_temp.length) {
      setValid((state) => ({ ...state, clinics: false }));
    }
    setForm((state) => ({ ...state, clinics: clinics_temp }));
  };

  const selectEmployees = (value) => {
    var employees_temp = [];
    if (form.employees.findIndex((c) => c.id === "all") !== -1) {
      if (value.findIndex((c) => c.id === "all") === -1) {
        employees_temp = value;
      } else {
        if (value.length > 1) {
          employees_temp = value.filter((v) => v.id !== "all");
        }
      }
    } else {
      if (value.findIndex((c) => c.id === "all") !== -1) {
        employees_temp = value.filter((v) => v.id === "all");
      } else {
        employees_temp = value;
      }
    }
    if (employees_temp.length) {
      setValid((state) => ({ ...state, employees: false }));
    }
    setForm((state) => ({ ...state, employees: employees_temp }));
  };

  const syncNow = async () => {
    setValid({ clinics: false, employees: false, from: false, to: false });
    if (
      (!form.clinics.length && !form.employees.length) ||
      !form.from ||
      !form.to
    ) {
      const error = {};
      if (!form.clinics.length && !form.employees.length) {
        error.clinics = true;
        error.employees = true;
      }
      if (!form.from) error.from = true;
      if (!form.to) error.to = true;
      setValid(error);
      // snackbar.setSnack((prev) => ({
      //   ...prev,
      //   open: true,
      //   variant: "warning",
      //   message: "Please complete form!",
      // }));
      return;
    }

    const ans = await prompt.openPrompt(
      "Confirm",
      `Do you want to start sync?`
    );
    if (!ans) {
      return;
    }

    const body = {
      clinicIds:
        form.clinics.length && form.clinics[0].id === "all"
          ? clinics.flatMap((c) => c.dayforceClinicId)
          : [...form.clinics].map((c) => c.dayforceClinicId),
      employeeIds:
        form.employees.length && form.employees[0].id === "all"
          ? employees.flatMap((c) => c.dayforceEmployeeId)
          : [...form.employees].map((c) => c.dayforceEmployeeId),
      startDate: dayjs(form.start).format("YYYY-MM-DD"),
      endDate: dayjs(form.to).format("YYYY-MM-DD"),
    };

    setPageLoading((state) => ({ ...state, page: true }));

    try {
      const res = await httpPost("lambda/schedule/sync", body);
      if (res.data?.success) {
        snackbar.setSnack((prev) => ({
          ...prev,
          open: true,
          variant: "success",
          message: "Sync now is starting!",
        }));
        setForm((state) => ({
          ...state,
          clinics: [],
          employees: [],
        }));
        setPageLoading((state) => ({ ...state, page: false }));
      } else {
        throw "not successful";
      }
    } catch (e) {
      setPageLoading((state) => ({ ...state, page: false }));
      httpErrorHandling(e);
      snackbar.setSnack((prev) => ({
        ...prev,
        open: true,
        variant: "warning",
        message: "Please try again!",
      }));
    }
  };

  const setMaxDate = () => {
    if (form.from) {
      const max = form.from.add(3, "months");
      setLatestDate(max);
    }
  };

  return (
    <>
      <Box component="div" display="flex" flexDirection="column" gap={2}>
        <PageTitle>Sync Now</PageTitle>
        <Box
          width="100%"
          maxWidth="640px"
          padding="24px"
          display="flex"
          flexDirection="column"
          backgroundColor={color}
          fontSize="14px"
          borderRadius="8px"
        >
          <p>
            Sync Now will refresh employee roster data for the selected period.
          </p>
          <p>Once started, Sync Now will be added into priority queue.</p>
        </Box>
        <Box
          width="100%"
          maxWidth="688px"
          display="flex"
          flexDirection="column"
          borderRadius="8px"
          border={"1px solid " + color}
        >
          <Box
            padding="24px"
            borderBottom={"1px solid " + color}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box color={grey[900]} fontWeight="600">
              Clinic(s)
            </Box>
            <Box width="356px">
              <Autocomplete
                multiple
                fullWidth
                id="clinics-option"
                options={clinics}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name || ""}
                value={form.clinics}
                onChange={(event, newValue) => {
                  selectClinics(newValue);
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected || form?.clinics[0]?.id === "all"}
                    />
                    {option.name}
                  </li>
                )}
                disabled={pageLoading.clinics}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      pageLoading.clinics
                        ? "Loading Clinis"
                        : "Select clinic(s) to sync"
                    }
                    placeholder="Add Clinic"
                    error={valid.clinics}
                  />
                )}
              />
            </Box>
          </Box>
          {/* <Box
          padding="24px"
          borderBottom={color}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box color={grey[900]} fontWeight="600">
            Employee(s)
          </Box>
          <Box width="356px">
            <Autocomplete
              multiple
              fullWidth
              id="employees-option"
              options={employees}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name || ""}
              value={form.employees}
              onChange={(event, newValue) => {
                selectEmployees(newValue);
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected || form?.employees[0]?.id === "all"}
                  />
                  {option.name}
                </li>
              )}
              disabled={pageLoading.employees}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    pageLoading.employees
                      ? "Loading Employees"
                      : "Select employee(s) to sync"
                  }
                  placeholder="Add Employees"
                  error={valid.employees}
                />
              )}
            />
          </Box>
        </Box> */}
          <Box
            padding="24px"
            borderBottom={color}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box color={grey[900]} fontWeight="600">
              Period
            </Box>
            <Box width="356px" display="flex" flexDirection="row" gap={1}>
              <DatePicker
                sx={{ width: "176px" }}
                label="From"
                value={form.from}
                onChange={(value) => {
                  setMaxDate();
                  if (value) setValid((state) => ({ ...state, from: false }));
                  setForm((state) => ({ ...state, from: value }));
                }}
                format="DD/MM/YYYY"
                maxDate={form.to || maxDate}
                minDate={minDate}
                slotProps={{
                  textField: {
                    error: valid.from,
                  },
                }}
              />
              <DatePicker
                sx={{ width: "176px" }}
                label="To"
                value={form.to}
                onChange={(value) => {
                  if (value) setValid((state) => ({ ...state, to: false }));
                  setForm((state) => ({ ...state, to: value }));
                }}
                minDate={form.from || null}
                maxDate={latestDate}
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    error: valid.to,
                  },
                }}
              />
            </Box>
          </Box>
          <Box
            padding="24px"
            borderBottom={color}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Box width="356px">
              <Button fullWidth variant="contained" onClick={() => syncNow()}>
                Start
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: 99999 }}
        open={pageLoading.page}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default SyncNow;
