import { Box, Icon, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import "./SyncDetailJobSyncFailedApi.scss";
import { useDispatch, useSelector } from "react-redux";
import { grey } from "@mui/material/colors";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { httpErrorHandling, httpPost } from "../../../services/http";
import { PromptContext, SnackContext } from "../../../components/Main/Main";
import pagination from "../../../services/pagination";
import { constant } from "../../../config/constant";
import dayjs from "dayjs";
import SyncFailedJobDetail from "./SyncFailedJobDetail";

const SyncDetailJobSyncFailedApi = ({ onRowClick, jobId }) => {
  const columns = [
    {
      field: "__check__",
      headerClassName: "table-data-header",
      width: 50,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      filter: false,
    },
    {
      field: "system",
      headerName: "System",
      minWidth: 120,
      flex: 1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      filter: false,
      renderCell: (param) => {
        return (
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            <Box>{param.row.system}</Box>
          </Box>
        );
      },
    },
    {
      field: "endpoint",
      headerName: "URL Endpoint",
      flex: 2.5,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      sortable: true,
      filter: false,
    },
    {
      field: "responseCode",
      headerName: "Response Code",
      flex: 1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      sortable: true,
      filter: false,
    },
    /* {
        field: "dateJobFinished",
        headerName: "Date Finished",
        minWidth: 80,
        flex: 1.5,
        headerClassName: "table-data-header",
        disableColumnMenu: true,
        sortable: false,
        filter: false,
      },
  
      {
        field: "status",
        headerName: "Duration (min)",
        minWidth: 120,
        flex: 1.1,
        headerClassName: "table-data-header table-data-header-checkbox",
        disableColumnMenu: true,
        sortable: false,
        filter: false,
        alignItems: "end",
        renderCell: (param) => {
          return (
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
            >
              <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                {param.row.duration}
              </Box>
            </Box>
          );
        },
      }, */
    {
      field: "responsePayload",
      headerName: "Message",
      minWidth: 80,
      flex: 2,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      sortable: false,
      filter: false,
      renderCell: (param) => {
        return (
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            <Box>{param.row.responsePayload?.message}</Box>
          </Box>
        );
      },
    },
    /*  {
        field: "status",
        headerName: "Sync",
        minWidth: 80,
        flex: 1,
        sortable: false,
        filter: false,
        headerClassName: "table-data-header",
        disableColumnMenu: true,
        renderCell: (param) => {
          return statusRow(param.row.status);
        },
      }, */
  ];

  const [rows, setRows] = useState([]);
  const [role, setRole] = useState(false);
  const user = useSelector((state) => state.auth);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCountState, setRowCountState] = React.useState(10);
  const [error, setError] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const snack = React.useContext(SnackContext);
  const dispatch = useDispatch();
  const [loadingData, setLoadingData] = useState(false);
  const [sortModel, setSortModel] = React.useState([
    { field: "name", sort: "asc" },
  ]);
  const [sorting, setSorting] = useState("!Id");
  const [searchField, setSearchField] = useState("");
  const [searchText, setSearchText] = useState("");
  const statuses = [
    { id: "RUNNING", text: "RUNNING", icon: "refresh" },
    { id: "COMPLETED", text: "COMPLETED", icon: "check" },
  ];
  const [statusObject, setStatusObject] = useState({});  

  const [clinicId, setClinicId] = useState([]);

  const prompt = React.useContext(PromptContext);

  const [status, setStatus] = useState("All");

  const statusOptions = [
    { id: "All", text: "All", icon: "" },
    { id: "Success", text: "Success", icon: "check" },
    { id: "Failed", text: "Failed", icon: "close" },
  ];

  useEffect(() => {
    getData();
  }, [page, searchField, sorting, status]);

  useEffect(() => {
    if (page != 0) setPage(0);
    else getData();
  }, [pageSize]);

  useEffect(() => {
    if (user.role === "super_admin") {
      setRole(true);
    }
    const obj = {};
    statuses.forEach((status) => {
      obj[status.id] = { text: status.text, icon: status.icon };
    });
    setStatusObject(obj);
  }, []);

  const getData = async () => {
    try {
      setError(false);
      setLoadingData(true);

      const filters = {};
      if (searchField) {
        filters.name = searchField;
      }

      if (clinicId.length) {
        filters.clinics = clinicId;
      }

      const body = {
        ...pagination.paginate(page, pageSize),
        filters: filters,
        order_by: sorting,
        job_id: jobId,
      };
      const res = await httpPost("sync/failed_api", body);
      const temp = [];
      if (res && res.data) {
        res.data.data.forEach((el, i) => {
          const responsePayload = toObject(el.responsePayload);
          temp.push({
            ...el,
            index: i,
            id: el.id,
            system: el.system,
            endpoint: el.endpoint,
            responseCode: el.responseCode,
            responsePayload:
              responsePayload !== "" && responsePayload !== null
                ? JSON.parse(responsePayload)
                : null,
            cellClassName: "table-data-cell",
            disableSelectionOnClick: true,
          });
        });
      }
      setRows(temp);
      setRowCountState(res.data.total);
      setLoadingData(false);
    } catch (err) {
      setError(true);
      setLoadingData(false);
      const error = httpErrorHandling(err);
    }
  };

  const toObject = (string) => {
    if (string) {
      return string
        .replace(/[()]/g, "")
        .replace(/[\\\n\r]/g, "")
        .slice(1, -1);
    } else {
      return null;
    }
  };

  const getDuration = (time1, time2) => {
    return dayjs(time1).diff(time2, "minutes");
  };

  const handleSortModelChange = async (newSort) => {
    await setSortModel(newSort);
    if (newSort?.length) {
      let sort = "";
      switch (newSort[0].field) {
        case "dateJobStarted":
          sort = "Date";
          break;
        case "jobType":
          sort = "Type";
          break;
        default:
          sort = "dateJobStarted";
          setSortModel([{ field: "dateJobStarted", sort: "desc" }]);
      }
      if (newSort[0].sort === "desc") {
        sort = "!" + sort;
      }
      setSorting(sort);
    }
  };

  const noResults = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Box display="flex" justifyContent="center" zIndex={99}>
        <h4 style={{ color: grey[600] }}>No Result</h4>
        <IconButton onClick={() => getData()} title="Reload">
          <Icon>refresh</Icon>
        </IconButton>
      </Box>
    </Box>
  );

  const noRows = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Box display="flex" justifyContent="center" zIndex={99}>
        <h4 style={{ color: grey[600] }}>There is no failed API call for this job</h4>
        {/* <IconButton onClick={() => getData()} title="Reload">
          <Icon>refresh</Icon>
        </IconButton> */}
      </Box>
    </Box>
  );

  const [param, setParam] = useState(null);
  const [detailDialog, setDetailDialog] = useState(false);

  const handleCloseDialog = () => {
    setSelectedId(null);
    setDetailDialog(false);
  };
  const handleOpenDialog = (data) => {
    setParam(data);
    setDetailDialog(true);
  };

  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      gap={2}
      className="failed-job-api"
    >
      <PageTitle>Failed API Call</PageTitle>
      <div className="table-data-container">
        <Box style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              getRowId={(row) => row.id}
              rows={rows}
              columns={columns}
              loading={loadingData}
              rowHeight={constant.tableRowHeight}
              headerHeight={constant.tableHeaderHeight}
              disableSelectionOnClick
              page={page}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50]}
              pagination
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowCount={rowCountState}
              sortingMode="server"
              sortingOrder={["desc", "asc"]}
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              disableColumnFilter
              components={{
                NoResultsOverlay: noResults,
                NoRowsOverlay: noRows,
              }}
              // checkboxSelection
              // onSelectionModelChange={setSelectedRow}
              // selectionModel={selectedRow}
              onRowClick={(data) => {                
                handleOpenDialog(data.row);
              }}
            />
          </div>
        </Box>
      </div>
      <SyncFailedJobDetail
        open={detailDialog}
        onClose={handleCloseDialog}
        syncDetail={param}
      ></SyncFailedJobDetail>
    </Box>
  );
};

export default SyncDetailJobSyncFailedApi;
