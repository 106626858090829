import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const datetime = {
  utcToLocal(date) {
    return dayjs(date)
      .utc("z")
      .local()      
      .format("DD MMM YYYY HH:mm:ss");
  },
};

export default datetime;
