import { CircularProgress, Icon, IconButton } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import PageSubTitle from "../../../components/PageSubTitle/PageSubTitle";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { httpErrorHandling, httpGet } from "../../../services/http";

const SyncDashboard = () => {
  const [error, setError] = useState({
    running: false,
    completed: false,
    failed: false,
  });
  const [loading, setLoading] = useState({
    running: false,
    completed: false,
    failed: false,
  });

  const [queueRunning, setQueueRunning] = useState(0);
  const [syncCompleted, setSyncCompleted] = useState(0);
  const [syncFailed, setSyncFailed] = useState(0);

  useEffect(() => {
    init();
  }, []);

  function init() {
    getJobQueueRunning();
    getSyncCompleted();
    getSyncFailed();
  }

  const getJobQueueRunning = async () => {
    try {
      setError((state) => ({ ...state, running: false }));
      setLoading((state) => ({ ...state, running: true }));
      const result = await httpGet("dashboard/job/running", {});
      setLoading((state) => ({ ...state, running: false }));
      if (result.data) {
        setQueueRunning(result.data.total);
      } else {
        throw "no data";
      }
    } catch (e) {
      httpErrorHandling(e);
      setLoading((state) => ({ ...state, running: false }));
      setError((state) => ({ ...state, running: true }));
    }
  };

  const getSyncCompleted = async () => {
    try {
      setError((state) => ({ ...state, completed: false }));
      setLoading((state) => ({ ...state, completed: true }));
      const result = await httpGet("dashboard/sync/completed/24", {});
      setLoading((state) => ({ ...state, completed: false }));
      if (result.data) {
        setSyncCompleted(result.data.total);
      } else {
        throw "no data";
      }
    } catch (e) {
      httpErrorHandling(e);
      setLoading((state) => ({ ...state, completed: false }));
      setError((state) => ({ ...state, completed: true }));
    }
  };

  const getSyncFailed = async () => {
    try {
      setError((state) => ({ ...state, failed: false }));
      setLoading((state) => ({ ...state, failed: true }));
      const result = await httpGet("dashboard/sync/failed/24", {});
      setLoading((state) => ({ ...state, failed: false }));
      if (result.data) {
        setSyncFailed(result.data.total);
      } else {
        throw "no data";
      }
    } catch (e) {
      httpErrorHandling(e);
      setLoading((state) => ({ ...state, failed: false }));
      setError((state) => ({ ...state, failed: true }));
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="24px"
      border={"1px solid " + grey[200]}
      borderRadius="8px"
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <PageTitle>Recent Syncs</PageTitle>
        <IconButton title="Refresh data" onClick={init}>
          <Icon
            sx={{ fontSize: "30px" }}
            className={loading.running || loading.completed ? "spining" : null}
          >
            refresh
          </Icon>
        </IconButton>
      </Box>

      <Box display="flex" flexDirection="row" marginTop="24px" gap={3}>
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="8px"
          width="100%"
          border={"1px solid " + grey[200]}
          padding="18px"
          flexBasis="20%"
        >
          <Box display="flex" flexDirection="row" gap={2} alignItems="center">
            <Box
              borderRadius="50%"
              height="40px"
              width="40px"
              backgroundColor="#2F80ED14"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Icon color="primary">equalizer</Icon>
            </Box>
            <PageTitle fontSize="18px">Queue</PageTitle>
          </Box>
          {loading.running === false && error.running === false ? (
            <Box
              display="flex"
              flexDirection="column"
              height="120px"
              alignItems="center"
            >
              <PageTitle fontSize="48px">{queueRunning}</PageTitle>
              <PageSubTitle>Syncs in queue</PageSubTitle>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              height="120px"
              justifyContent="center"
              alignItems="center"
            >
              {loading.running === true ? (
                <CircularProgress />
              ) : (
                <Box>
                  <IconButton onClick={getJobQueueRunning} title="Reload Data">
                    <Icon sx={{ fontSize: "40px" }}>refresh</Icon>
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="8px"
          width="100%"
          border={"1px solid " + grey[200]}
          padding="18px"
          flexBasis="80%"
        >
          <Box display="flex" flexDirection="row" gap={2} alignItems="center">
            <Box
              borderRadius="50%"
              height="40px"
              width="40px"
              backgroundColor="#2F80ED14"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Icon color="primary">equalizer</Icon>
            </Box>
            <PageTitle fontSize="18px">Past 24h</PageTitle>
          </Box>
          <Box display="flex" flexDirection="row" gap={10}>
            {loading.running === false && error.running === false ? (
              <Box
                display="flex"
                flexDirection="column"
                height="120px"
                alignItems="center"
                width="25%"
              >
                <PageTitle fontSize="48px">{syncCompleted}</PageTitle>
                <PageSubTitle>Successful syncs</PageSubTitle>
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                height="120px"
                justifyContent="center"
                alignItems="center"
                width="25%"
              >
                {loading.running === true ? (
                  <CircularProgress />
                ) : (
                  <Box>
                    <IconButton onClick={getSyncCompleted} title="Reload Data">
                      <Icon sx={{ fontSize: "40px" }}>refresh</Icon>
                    </IconButton>
                  </Box>
                )}
              </Box>
            )}

            {loading.failed === false && error.failed === false ? (
              <Box
                display="flex"
                flexDirection="column"
                height="120px"
                alignItems="center"
                width="25%"
              >
                <PageTitle fontSize="48px">{syncFailed}</PageTitle>
                <PageSubTitle>Failed syncs</PageSubTitle>
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                height="120px"
                justifyContent="center"
                alignItems="center"
                width="25%"
              >
                {loading.failed === true ? (
                  <CircularProgress />
                ) : (
                  <Box>
                    <IconButton onClick={getSyncFailed} title="Reload Data">
                      <Icon sx={{ fontSize: "40px" }}>refresh</Icon>
                    </IconButton>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SyncDashboard;
