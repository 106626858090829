import { Box } from "@mui/material";
import React from "react";

const CenterLayout = ({ children }) => {
  return (
    <>
      <Box
        display="flex"
        minHeight="100vh"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Box>
    </>
  );
};

export default CenterLayout;
