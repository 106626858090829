import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import PageTitle from "../../components/PageTitle/PageTitle";
import ClinicDashboard from "./components/ClinicDashboard";
import EmployeDashboard from "./components/EmployeeDashboard";
import FailedSyncDashboard from "./components/FailedSyncDashboard";
import SyncDashboard from "./components/SyncDashboard";
import "./DashboardPage.scss";

const DashboardPage = () => {
  return (
    <>
      <Box width="100%">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ClinicDashboard></ClinicDashboard>
          </Grid>
          <Grid item xs={12} md={6}>
            <EmployeDashboard></EmployeDashboard>
          </Grid>
          <Grid item xs={12}>
            <SyncDashboard></SyncDashboard>
          </Grid>
          
        </Grid>
      </Box>
    </>
  );
};

export default DashboardPage;
