import {
  Avatar,
  Badge,
  Icon,
  IconButton,
  ListItemIcon,
  ListSubheader,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EventBus from "../../common/EventBus";
import { SnackContext } from "../Main/Main";
import "./TopMenuBar.scss";

const TopMenuBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const user = useSelector((state) => state.auth);

  const snack = React.useContext(SnackContext);
  const navigate = useNavigate();

  const signout = () => {
    // navigate('/logout');
    EventBus.$dispatch("app.logout", { logout: true });
  };

  const navigateTo = (page) => {
    navigate(page);
    handleClose();
  };

  return (
    <div className="header-container">
      <div className="header-toolbar">
        <div className="header-btn">
          {/* <Badge variant="dot" overlap="circular" color="error">
            <IconButton size="small" aria-label="Notification">
              <Icon>notifications</Icon>
            </IconButton>
          </Badge> */}
        </div>
        <div className="header-btn">
          <IconButton
            aria-label="Notification"
            id="menu-button"
            aria-controls={open ? "top-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <Avatar className="top-menu-avatar">
              {user.firstname ? user.firstname.substring(0, 1) : "A"}
            </Avatar>
          </IconButton>
          <Menu
            id="top-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "menu-button",
            }}
            className="top-menu-container"
          >
            <ListSubheader className="top-menu-label">
              Hi, {user.firstname}
            </ListSubheader>            
            {/* <MenuItem onClick={handleClose} className="top-menu-item">
              <ListItemIcon>
                <Icon>account_circle_outline</Icon>
              </ListItemIcon>
              My account
            </MenuItem>
            <MenuItem
              onClick={() => navigateTo("users")}
              className="top-menu-item"
            >
              <ListItemIcon>
                <Icon>group</Icon>
              </ListItemIcon>
              User Management
            </MenuItem> */}
            <MenuItem onClick={() => signout()} className="top-menu-item">
              <ListItemIcon>
                <Icon>logout</Icon>
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default TopMenuBar;
