import { config } from "../config/environment";
import axios from "axios";
import EventBus from "../common/EventBus";

const apiVersion = "v1";

axios.defaults.timeout = 12000;

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "Access-Control-Allow-Credentials": true,
};

export function httpPost(url, body, api = true) {
  return axios({
    method: "post",
    url: baseUrl(url, api),
    data: body,
    headers: headers,
  });
}

export function httpPostFormData(url, body, api = true) {
  let data = new FormData();
  for (const field in body) {
    data.append(field, body[field]);
  }

  return axios({
    method: "post",
    url: baseUrl(url, api),
    data: data,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function httpGet(url, params = {}, api = true) {
  return axios({
    method: "get",
    url: baseUrl(url, api),
    params: params,
    headers: headers,
  });
}

export function httpPut(url, body, api = true) {
  return axios({
    method: "put",
    url: baseUrl(url, api),
    data: body,
    headers: headers,
  });
}

export function httpDelete(url, params = {}, api = true) {
  return axios({
    method: "delete",
    url: baseUrl(url, api),
    params: params,
    headers: headers,
  });
}

const baseUrl = (url, api) => {
  return config.API_URL + "/" + (api === true ? apiVersion + "/" : "") + url;
};

export const httpErrorHandling = (err) => {
  console.log(err);
  if (err?.response?.status === 401) {
    EventBus.$dispatch("app.logout");
  }
  return err?.response?.data;
};
