import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Icon,
  IconButton,
  setRef,
  TextField,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PromptContext, SnackContext } from "../../../components/Main/Main";
import PageSubTitle from "../../../components/PageSubTitle/PageSubTitle";
import PageTitle from "../../../components/PageTitle/PageTitle";
import {
  httpDelete,
  httpErrorHandling,
  httpGet,
  httpPost,
  httpPut,
} from "../../../services/http";
import { loading, stoploading } from "../../../store/actions/loadingAction";
import "./JobRole.scss";

const JobRole = ({}) => {
  const dispatch = useDispatch();
  const snack = useContext(SnackContext);

  const [roles, setRoles] = useState([]);
  const [tempRow, setTempRow] = useState({});

  const prompt = useContext(PromptContext);

  useEffect(() => {
    getJobRole();
  }, []);

  const getJobRole = async () => {
    dispatch(loading());
    try {
      const res = await httpGet("config/employee_job_role", {});
      if (res.data) {
        const rows = res.data.map((d) => ({
          ...d,
          mode: "view",
          loading: false,
          id: d.dayforceJobId,
          errors: {
            dayforceJobId: false,
            zenotiJobId: false,
            zenotiRoleId: false,
          },
        }));
        setRoles(rows);
        console.log(rows);
      }
      dispatch(stoploading());
    } catch (e) {
      httpErrorHandling(e);
      dispatch(stoploading());
    }
  };

  const updateDayforceJobId = (value, i) => {
    setRoles((state) => {
      const states = [...state];
      states[i].dayforceJobId = value;
      return states;
    });
  };

  const updateZenotiJobId = (value, i) => {
    setRoles((state) => {
      const states = [...state];
      states[i].zenotiJobId = value;
      return states;
    });
  };

  const updateZenotiRoleId = (value, i) => {
    setRoles((state) => {
      const states = [...state];
      states[i].zenotiRoleId = value;
      return states;
    });
  };

  const updateIsBookable = (value, i) => {
    setRoles((state) => {
      const states = [...state];
      states[i].isBookable = value;
      return states;
    });
  };

  const saveData = async (i) => {
    const values = { ...roles[i] };
    const errors = {
      dayforceJobId: false,
      zenotiJobId: false,
      zenotiRoleId: false,
    };
    if (!values.dayforceJobId || !values.zenotiJobId || !values.zenotiRoleId) {
      if (!values.dayforceJobId) errors.dayforceJobId = true;
      if (!values.zenotiJobId) errors.zenotiJobId = true;
      if (!values.zenotiRoleId) errors.zenotiRoleId = true;
      setErrors(i, errors);
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        message: "Please complete all three ID fields to save mapping!",
        variant: "warning",
      }));
      return;
    }
    let ans = await prompt.openPrompt(
      "Confirm",
      `Do you want to save this data?`
    );
    if (!ans) return;
    setRoles((state) => {
      const states = [...state];
      states[i].mode = "view";
      states[i].loading = true;
      return states;
    });
    try {
      if (values.id === null) {
        const result = await httpPost("config/employee_job_role", {
          dayforceJobId: values.dayforceJobId,
          zenotiJobId: values.zenotiJobId,
          zenotiRoleId: values.zenotiRoleId,
          isBookable: values.isBookable,
        });
        if (result.data?.success) {
          snack.setSnack((prev) => ({
            ...prev,
            open: true,
            message: "Added successfully",
            variant: "success",
          }));
          setRoles((state) => {
            const states = [...state];
            states[i].mode = "view";
            states[i].loading = false;
            states[i].id = result.data?.data.dayforceJobId;
            return states;
          });
        } else {
          throw "Not Successful";
        }
      } else {
        const result = await httpPut("config/employee_job_role", {
          dayforceJobId: values.dayforceJobId,
          zenotiJobId: values.zenotiJobId,
          zenotiRoleId: values.zenotiRoleId,
          isBookable: values.isBookable,
        });
        if (result.data?.success) {
          snack.setSnack((prev) => ({
            ...prev,
            open: true,
            message: "Updated successfully",
            variant: "success",
          }));
          setRoles((state) => {
            const states = [...state];
            states[i].mode = states[i].id === null ? "new" : "view";
            states[i].loading = false;
            return states;
          });
        } else {
          throw "Not Successful";
        }
      }
    } catch (e) {
      setRoles((state) => {
        const states = [...state];
        states[i].mode = states[i].id === null ? "new" : "edit";
        states[i].loading = false;
        return states;
      });
      httpErrorHandling(e);
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        message: "Please try again",
        variant: "warning",
      }));
      setTimeout(() => {
        const field = document.getElementById("job-id-" + i);
        field.focus();
      }, 200);
    }
  };

  const editRow = (i, mode) => {
    if (roles[i].id !== null) {
      setRoles((state) => {
        const states = [...state];
        if (mode === "start") {
          states[i].mode = "edit";
          setTempRow({ ...state[i] });
          setTimeout(() => {
            const field = document.getElementById("job-id-" + i);
            field.focus();
          }, 200);
        } else if (mode === "cancel") {
          states[i] = tempRow;
          states[i].mode = "view";
        }
        return states;
      });
    } else {
      setRoles((state) => {
        const states = [...state];
        states.splice(i, 1);
        return states;
      });
    }
  };

  const addNewItem = () => {
    setRoles((state) => {
      const states = [...state];
      states.push({
        id: null,
        dayforceJobId: "",
        zenotiJobId: "",
        zenotiRoleId: "",
        isBookable: false,
        mode: "new",
        loading: false,
        errors: {
          dayforceJobId: false,
          zenotiJobId: false,
          zenotiRoleId: false,
        },
      });
      return states;
    });
    setTimeout(() => {
      const field = document.getElementById("job-role-" + roles.length);
      field.focus();
    }, 200);
  };

  const removeRow = async (i) => {
    let ans = await prompt.openPrompt(
      "Confirm",
      `Do you want to remove ${roles[i].dayforceJobId} job role? (irreversible)`
    );
    if (!ans) return;
    setLoading(i, true);
    try {
      const result = await httpDelete(
        "config/employee_job_role/" + roles[i].dayforceJobId
      );
      if (result.data?.success) {
        setRoles((state) => {
          const states = [...state];
          states.splice(i, 1);
          return states;
        });
        snack.setSnack((prev) => ({
          ...prev,
          open: true,
          message: "Data is deleted successfuly",
          variant: "success",
        }));
      } else {
        throw "Not Successful";
      }
    } catch (e) {
      httpErrorHandling(e);
      setLoading(i, false);
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        message: "Please try again",
        variant: "warning",
      }));
    }
  };

  const setLoading = (i, value = false) => {
    setRoles((state) => {
      const states = [...state];
      states[i].loading = value;
      return states;
    });
  };

  const setErrors = (i, errors) => {
    setRoles((state) => {
      const states = [...state];
      states[i].errors = errors;
      return states;
    });
  };

  const handleKeydown = (event, i) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      event.target.blur();
      saveData(i);
    }
  };

  return (
    <Box
      border={"1px solid " + grey[200]}
      padding="24px"
      display="flex"
      flexDirection="column"
      gap={2}
      justifyContent="space-between"
    >
      <Box maxWidth="50%">
        <PageTitle fontSize="16px">Dayforce Employee Job Role</PageTitle>
        <PageSubTitle fontSize="14px">
          <p>
            Each employee in Zenoti has one assigned job (see Employee {">"}{" "}
            General
            {">"} Job Info), but can have multiple security roles assigned (see
            Employee {">"} Employee Roles).
          </p>
          <p>
            All Dayforce work assignments are translated into Zenoti security
            roles. Whereas, only one work assignment will be translated into a
            Zenoti job (in most cases, an employee’s primary work assignment).
          </p>
          <p>
            This table manages the relationship between a JobXRefCode for
            Dayforce job assignments, a GUID for Zenoti job and a GUID for
            Zenoti security_role.
          </p>
        </PageSubTitle>
      </Box>
      <Box width="100%">
        <table className="config-table">
          <thead>
            <tr>
              <th>Dayforce Job Id</th>
              <th>Zenoti Job Id</th>
              <th>Zenoti Role Id</th>
              <th>Is Bookable</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {roles.map((s, i) => {
              return (
                <tr key={i}>
                  <td>
                    <TextField
                      id={"job-role-" + i}
                      placeholder="Dayforce Job ID"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={s.dayforceJobId}
                      onChange={(e) => updateDayforceJobId(e.target.value, i)}
                      onKeyDown={(e) => handleKeydown(e, i)}
                      InputProps={{
                        readOnly: true && s.mode !== "new",
                        disabled: true && s.mode !== "new",
                      }}
                      error={s.errors.dayforceJobId}
                    />
                  </td>
                  <td>
                    <TextField
                      id={"job-id-" + i}
                      placeholder="Zenoti Job ID"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={s.zenotiJobId}
                      onChange={(e) => updateZenotiJobId(e.target.value, i)}
                      InputProps={{
                        readOnly: s.mode === "view",
                        disabled: s.mode === "view",
                      }}
                      onKeyDown={(e) => handleKeydown(e, i)}
                      error={s.errors.zenotiJobId}
                    />
                  </td>
                  <td>
                    <TextField
                      id={"zenoti-role-" + i}
                      placeholder="Zenoti Role Id"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={s.zenotiRoleId}
                      onChange={(e) => updateZenotiRoleId(e.target.value, i)}
                      onKeyDown={(e) => handleKeydown(e, i)}
                      InputProps={{
                        readOnly: s.mode === "view",
                        disabled: s.mode === "view",
                      }}
                      error={s.errors.zenotiRoleId}
                    />
                  </td>
                  <td align="center">
                    <Checkbox
                      checked={s.isBookable}
                      onChange={(e) => updateIsBookable(e.target.checked, i)}
                      readOnly={s.mode === "view"}
                      disabled={s.mode === "view"}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </td>
                  <td align="right">
                    {s.loading === false ? (
                      s.mode === "view" ? (
                        <Box
                          display="flex"
                          flexDirection="row"
                          gap={1}
                          width={72}
                        >
                          <IconButton
                            onClick={() => editRow(i, "start")}
                            size="small"
                          >
                            <Icon>edit</Icon>
                          </IconButton>
                          <IconButton size="small" onClick={() => removeRow(i)}>
                            <Icon>delete_outline</Icon>
                          </IconButton>
                        </Box>
                      ) : (
                        <div>
                          <IconButton onClick={() => saveData(i)} size="small">
                            <Icon>check</Icon>
                          </IconButton>
                          <IconButton
                            onClick={() => editRow(i, "cancel")}
                            size="small"
                          >
                            <Icon>close</Icon>
                          </IconButton>
                        </div>
                      )
                    ) : (
                      <Box display="flex" justifyContent="center" width={72}>
                        <CircularProgress size={20} />
                      </Box>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Button
          sx={{ marginTop: "8px", textTransform: "capitalize" }}
          onClick={addNewItem}
        >
          <Icon>add</Icon>
          Add New
        </Button>
      </Box>
    </Box>
  );
};

export default JobRole;
