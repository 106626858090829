const initialState = { value: "hide" };

const loadingReducer = (state = initialState, action) => {
  if (action.type === "loading") {
    console.log(state, action.type);
    const newState = {...state, value: "show" };
    console.log(newState, action.type);
    return newState;
  } else if (action.type === "stoploading") {
    return {
      ...state,
      value: "hide",
    };
  } else {
    return state;
  }
};
export default loadingReducer;
