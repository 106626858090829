import React from "react";
import Slide from "@mui/material/Slide";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  useMediaQuery,
} from "@mui/material";

import "./SyncFailedJobDetail.scss";
import { CopyBlock, monoBlue } from "react-code-blocks";
import DateFormat from "../../../components/DateFormat";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function SyncFailedJobDetail(props) {
  const { onClose, selectedValue, open, syncDetail } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const [maxWidth, setMaxWidth] = React.useState("lg");

  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const jsonFormatHeader = (data) => {
    try {
      let str = data
        .replaceAll(/[\"{} \(\)]+/g, "")
        .replaceAll("\\n", " ")
        .replaceAll("'", "")
        .replaceAll("False", "false")
        .replaceAll("True", "true");
      const temp = str.split(":");
      const json = {};
      json[temp[0]] = temp[1].replaceAll("\n", "");
      return JSON.stringify(json, null, "\t");
    } catch (e) {
      console.log(e);
      return data;
    }
  };
  const jsonFormat = (data) => {
    try {
      let str = data
        .replaceAll(/[ \(\)]+/g, "")
        .replaceAll("\\n", "")
        .replaceAll("'", '"')
        .replaceAll("False", "false")
        .replaceAll("True", "true")
        .replaceAll("None", null);
      const json = JSON.parse(str);
      return JSON.stringify(json, null, "\t");
    } catch (e) {
      console.log(e);
      return data;
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="dialog-slide"
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      fullWidth={true}
    >
      <DialogTitle className="modal-title">
        Failed API Call Detail
        <Box
          title="Close"
          position="absolute"
          top="20px"
          right="12px"
          sx={{ cursor: "pointer" }}
          onClick={handleClose}
        >
          <Icon>close</Icon>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Box height="64vh" overflow="auto" width="100%">
          <Box padding="12px 24px">
            {syncDetail ? (
              <div>
                <table className="table-info">
                  <tbody>
                    <tr>
                      <td>Job ID Code</td>
                      <td>{syncDetail?.jobId}</td>
                    </tr>
                    <tr>
                      <td>System</td>
                      <td>{syncDetail?.system}</td>
                    </tr>
                    <tr>
                      <td>Endpoint</td>
                      <td>{syncDetail?.endpoint}</td>
                    </tr>
                    <tr>
                      <td>Request Timestamp</td>
                      <td>
                        <DateFormat format="DD/MM/YYYY HH:mm:ss">
                          {syncDetail?.dateApiRequest}
                        </DateFormat>
                      </td>
                    </tr>
                    <tr>
                      <td>Response Timestamp</td>
                      <td>
                        {syncDetail?.dateApiResponse ? (
                          <DateFormat format="DD/MM/YYYY HH:mm:ss">
                            {syncDetail?.dateApiResponse}
                          </DateFormat>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h4>Request</h4>
                <table className="table-info">
                  <tbody>
                    <tr>
                      <td>Header</td>
                      <td>
                        <CopyBlock
                          text={jsonFormatHeader(syncDetail?.requestHeader)}
                          language="json"
                          showLineNumbers={true}
                          wrapLines={true}
                          theme={monoBlue}
                          codeBlock
                          customStyle={{                                                        
                            borderRadius: '8px',                            
                            fontSize: '0.85rem',                              
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Body</td>
                      <td>
                        <CopyBlock
                          text={jsonFormat(syncDetail?.requestBody)}
                          language="json"
                          showLineNumbers={true}
                          wrapLines={true}
                          theme={monoBlue}
                          codeBlock
                          customStyle={{                                                        
                            borderRadius: '8px',                            
                            fontSize: '0.85rem',                              
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h4>Response</h4>
                <table className="table-info">
                  <tbody>
                    <tr>
                      <td>Response Code</td>
                      <td>{syncDetail?.responseCode}</td>
                    </tr>
                    <tr>
                      <td>Header</td>
                      <td>
                        <CopyBlock
                          text={syncDetail?.responseHeader}
                          language="json"
                          showLineNumbers={true}
                          wrapLines={true}
                          theme={monoBlue}
                          codeBlock
                          customStyle={{                                                        
                            borderRadius: '8px',                            
                            fontSize: '0.85rem',                              
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Payload</td>
                      <td>
                        <CopyBlock
                          text={JSON.stringify(
                            syncDetail?.responsePayload,
                            null,
                            "\t"
                          )}
                          language="JSON"
                          showLineNumbers={true}
                          wrapLines={true}
                          theme={monoBlue}
                          codeBlock
                          customStyle={{                                                        
                            borderRadius: '8px',                            
                            fontSize: '0.85rem',                              
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : null}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus color="dark">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SyncFailedJobDetail;
