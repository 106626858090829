import {
  Box,
  Button,
  Icon,
  IconButton,
} from "@mui/material";
import PageTitle from "../../components/PageTitle/PageTitle";
import { DataGrid } from "@mui/x-data-grid";
import { httpDelete, httpErrorHandling, httpPost } from "../../services/http";
import React, { useState } from "react";
import "./UsersPage.scss";
import UserForm from "../../components/UserForm/UserForm";
import { useSelector } from "react-redux";
import pagination from "../../services/pagination";
import { PromptContext, SnackContext } from "../../components/Main/Main";
import text from "../../config/text";
import { constant } from "../../config/constant";

const UsersPage = () => {
  const columns = [
    {
      field: "username",
      headerName: "Username",
      minWidth: 100,
      flex: 1,
      headerClassName: "table-data-header",
    },
    {
      field: "firstname",
      headerName: "First Name",
      minWidth: 120,
      flex: 1,
      headerClassName: "table-data-header",
    },
    {
      field: "lastname",
      headerName: "Last Name",
      minWidth: 120,
      flex: 1,
      headerClassName: "table-data-header",
    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 80,
      flex: 1,
      headerClassName: "table-data-header",
    },
    {
      field: "phone",
      headerName: "Phone",
      minWidth: 70,
      flex: 1,
      headerClassName: "table-data-header",
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 120,
      flex: 1.2,
      headerClassName: "table-data-header",
    },
    {
      field: "option",
      headerName: "",
      width: 50,
      flex: 0,
      headerClassName: "table-data-header",
      sortable: false,
      filter: false,
      renderCell: (param) => {
        return (
          <IconButton
            title="Delete User"
            disabled={!role}
            onClick={() => deleteData(param.row)}
            size="small"
          >
            <Icon className="cell-icon">delete_outline</Icon>
          </IconButton>
        );
      },
    },
  ];

  const [rows, setRows] = useState([]);
  const [role, setRole] = useState(false);
  const user = useSelector((state) => state.auth);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCountState, setRowCountState] = React.useState(10);
  const [error, setError] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const snack = React.useContext(SnackContext);
  const [loadingData, setLoadingData] = useState(false);

  React.useEffect(() => {
    if (user.role === "super_admin") {
      setRole(true);
    }
    getData(page, pageSize);
  }, [page, pageSize]);

  const getData = async (page, pageSize) => {
    try {
      setError(false);
      setLoadingData(true);
      const res = await httpPost(
        "user/list",
        pagination.paginate(page, pageSize)
      );
      const temp = [];
      if (res && res.data) {
        res.data.data.forEach((el, i) => {
          temp.push({
            username: el.username,
            firstname: el.firstname,
            lastname: el.lastname,
            role: el.role,
            phone: el.phone,
            email: el.email,
            cellClassName: "table-data-cell",
          });
        });
      }
      setRows(temp);
      setRowCountState(res.data.total);
      setLoadingData(false);
    } catch (err) {
      setError(true);
      setLoadingData(false);
      const error = httpErrorHandling(err);
    }
  };

  const [propertyDialog, setPropertyDialog] = useState(false);

  const handleCloseDialog = () => {
    setSelectedId(null);
    setPropertyDialog(false);
    getData(page, pageSize);
  };
  const handleOpenDialog = () => {
    setPropertyDialog(true);
  };

  const prompt = React.useContext(PromptContext);
  const deleteData = async (data) => {
    if (data.role === "super_admin") {
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        variant: "warning",
        message: "Super admin cannot be deleted",
      }));
      return;
    }
    let ans = await prompt.openPrompt(
      "Confirm",
      `Do you want to remove user ${data.username}?`
    );          
    if (!ans) return;    

    setLoadingData(true);    
    try {
      const result = await httpDelete("user/delete/" + data.username);
      if (result && result.data.success) {
        snack.setSnack((prev) => ({
          ...prev,
          open: true,
          variant: "success",
          message: "User deleted",
        }));
        getData(page, pageSize);
      } else {
        snack.setSnack((prev) => ({
          ...prev,
          open: true,
          variant: "warning",
          message: result?.detail || text.tryAgain,
        }));
      }
      setLoadingData(false);
    } catch (err) {
      setLoadingData(false);
      const e = httpErrorHandling(err);
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        variant: "warning",
        message: e.detail || text.tryAgain,
      }));
    }
  };

  return (
    <Box component="div" display="flex" flexDirection="column" gap={2}>
      <PageTitle>User Management</PageTitle>
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        width="100%"
        alignContent="center"
        justifyContent="space-between"
      >
        {/* <FormControl>
          <TextField
            size="small"
            id="search-field"
            label="Search"
            variant="outlined"
          />
        </FormControl> */}
        <Box></Box>
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          gap={1}
        >
          <Button
            startIcon={<Icon sx={{ fontSize: "14px" }}>person_add</Icon>}
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            color="grey"
            onClick={handleOpenDialog}
            disabled={!role}
          >
            Add User
          </Button>
        </Box>
      </Box>
      <div className="table-data-container">
        <Box style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              getRowId={(row) => row.username}
              rows={rows}
              columns={columns}
              loading={loadingData}
              rowHeight={constant.tableRowHeight}
              headerHeight={constant.tableHeaderHeight}
              disableSelectionOnClick
              page={page}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50]}
              pagination
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowCount={rowCountState}              
            />
          </div>
        </Box>
      </div>
      <UserForm
        open={propertyDialog}
        onClose={handleCloseDialog}
        id={selectedId}
      ></UserForm>
    </Box>
  );
};

export default UsersPage;
