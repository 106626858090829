import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import "./PromptAlert.scss";

const PromptAlert = ({ title, message, open, handleClose, ...other }) => {
  const closePrompt = (ans) => {
    handleClose(ans);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={() => closePrompt(false)}
        aria-labelledby="alert-prompt-title"
        aria-describedby="alert-prompt-description"
        {...other}
      >
        <DialogTitle id="alert-prompt-title" className="alert-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-prompt-description" className="alert-content">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={() => closePrompt(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => closePrompt(true)}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PromptAlert;
