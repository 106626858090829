import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import SideNavigation from "../../components/SideNavigation/SideNavigation";
import { constant } from "../../config/constant";
import theme from "../../config/theme";

const HomeSidebar = () => {
  return (
    <Box
      id="home-sidebar"
      component="div"
      position="fixed"
      left={0}
      top={0}
      height="100vh"
      sx={{
        width: {
          lg: `calc(${constant.sideBarWidth}  - (${constant.sideBarPaddingX}) * 2 )`,
          xs: `calc(${constant.sideBarWidthSm} - (${constant.sideBarPaddingXSm}) * 2 )`,
        },
        padding: {
          lg: `${constant.sideBarPaddingY} ${constant.sideBarPaddingX}`,
          xs: `${constant.sideBarPaddingY} ${constant.sideBarPaddingXSm}`,
        },
        transition: constant.sideBarTransition,
        ":hover":{
          padding: {            
            xs: `${constant.sideBarPaddingY} ${constant.sideBarPaddingX}`,
          },
          width: {
            xs: `calc(${constant.sideBarWidth} - 48px)`,            
          },
        },              
        zIndex: {
          xs: 999,
          lg: 1,
        },
        overflow: 'hidden',        
      }}
      bgcolor={theme.palette.tertiary.main}
    >
      <Box
        paddingBottom="32px"
        sx={{
          fontSize:{
            sx: '12px',
            lg: '16px',
          }
        }}
        color={grey[100]}
      >
        Admin
      </Box>
      <Box component="div">
        <SideNavigation></SideNavigation>
      </Box>
    </Box>
  );
};

export default HomeSidebar;
