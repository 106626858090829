import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";

import PageSubTitle from "../../components/PageSubTitle/PageSubTitle";
import PageTitle from "../../components/PageTitle/PageTitle";
import JobRole from "./components/JobRole";
import LeaveType from "./components/LeaveType";
import ShiftType from "./components/ShiftType"; 

import "./MasterData.scss";

const MasterData = () => {
  const [tab, setTab] = useState(1);

  const tabChange = (e, val) => {
    console.log(val);
    setTab(val);
  };

  const TabContent = [
    // <ShiftType></ShiftType>,
    // <LeaveType></LeaveType>,
    <JobRole></JobRole>,
  ];

  return (
    <Box component="div" display="flex" flexDirection="column" gap={2}>
      <Box>
        <PageTitle>Master Data Management</PageTitle>        
      </Box>
      <Box>
        <Tabs
          value={tab}
          onChange={tabChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="secondary tabs example"
        >
          {/* <Tab
            value={1}
            label="Shift Type"
            sx={{ textTransform: "capitalize" }}
          />
          <Tab
            value={2}
            label="Leave Type"
            sx={{ textTransform: "capitalize" }}
          /> */}
          <Tab value={1} label="Roles" sx={{ textTransform: "capitalize" }} />
        </Tabs>
      </Box>
      {TabContent[tab - 1]}
    </Box>
  );
};

export default MasterData;
