import { Box } from "@mui/material";
import { useState } from "react";
import CompletedSync from "./components/CompletedSync";
import InProgress from "./components/InProgress";
import SyncDetail from "./components/SyncDetail";
import "./SyncHistory.scss";

const SyncHistory = () => {
  const [mode, setMode] = useState("list");

  const [listClass, setListClass] = useState("");
  const [detailClass, setDetailClass] = useState("");
  const [syncData, setSyncData] = useState({});

  const selectDetail = (param) => {
    setMode("detail");
    setSyncData(param);
  };

  const onBackButtonClick = () => {
    setMode("list");
  };

  return (
    <Box component="div" display="flex" flexDirection="column" gap={2}>
      {mode === "list" ? (
        <div className={listClass}>
          <Box display="flex" flexDirection="column" gap={4}>
            <InProgress></InProgress>
            <CompletedSync
              onRowClick={(param) => {
                selectDetail(param);
              }}
            ></CompletedSync>
          </Box>
        </div>
      ) : (
        <div className={detailClass}>
          <SyncDetail
            syncData={syncData}
            onBackButtonClick={() => onBackButtonClick()}
          ></SyncDetail>
        </div>
      )}
    </Box>
  );
};

export default SyncHistory;
