import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CenterLayout from "../../layouts/CenterLayout";
import storage from "../../services/storage";
import { logout } from "../../store/actions/authAction";

const LogoutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    storage.clear();
    dispatch(logout());    
    setTimeout(() => {
      navigate("/login");
    }, 1500);
  },[]);

  return (
    <CenterLayout>
      <Box>You are logged out.</Box>
    </CenterLayout>
  );
};

export default LogoutPage;
