import {
  alpha,
  Box,
  Button,
  Checkbox,
  Icon,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect } from "react";
import "./FilterOption.scss";

const defaultConfig = {
  id: "id",
  text: "text",
  icon: "icon",
};

const FilterOption = ({
  selected,
  items = [],
  onSelectItem,
  label = "Option",
  config = defaultConfig,
  multiple = false,
  onClose,
  disabled = false,
  onError,
  width = "88px",
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [updated, setUpdated] = React.useState(null);
  const [changed, setChanged] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (!items.length) {
      onError("Empty");
    }
    setUpdated(null);
    setChanged(false);
  };

  useEffect(() => {
    if (updated !== null) handleClose();
  }, [updated]);

  const handleClose = () => {
    setAnchorEl(null);
    if (multiple === true && changed === true) {
      setChanged(false);
      setUpdated(true);
    } else {
      if (onClose) onClose(updated === true ? true : false);
    }
  };

  const selectItem = (item) => {
    if (!multiple) {
      onSelectItem(item);
      setUpdated(true);
    } else {
      const temp = [...selected];
      if (temp.indexOf(item) !== -1) {
        temp.splice(temp.indexOf(item), 1);
      } else {
        temp.push(item);
      }
      onSelectItem(temp);
      setChanged(true);
    }
  };

  const clearSelected = () => {
    const temp = [];
    onSelectItem(temp);
    setUpdated(true);
  };

  return (
    <>
      <Button
        id="filter-option"
        aria-controls={open ? "filter-option" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableElevation
        disabled={disabled}
        onClick={handleClick}
        endIcon={
          <Icon sx={{ fontSize: "14px" }}>
            {open === false ? "keyboard_arrow_down" : "keyboard_arrow_up"}
          </Icon>
        }
        color="grey"
        sx={{
          textTransform: "capitalize",
          width: width,
          justifyContent: "space-between",
          gap: 0.2,
        }}
        size="small"
        title={
          !multiple
            ? items.filter((i) => i[config.id] === selected)[0][config.text]
            : ""
        }
      >
        {label}
      </Button>
      <Menu
        id="filter-option"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="filter-option-menu"
      >
        <Box display="flex" flexDirection="column">
          <Box maxHeight="200px" overflow="auto">
            {items.map((item, index) => {
              return (
                <MenuItem
                  onClick={() => selectItem(item[config.id])}
                  disableRipple
                  sx={{ padding: 0 }}
                  className={
                    selected === item[config.id] && !multiple ? "selected" : ""
                  }
                  key={index}
                >
                  {!multiple ? (
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="row"
                      gap={1}
                      alignItems="center"
                      padding="8px"
                      borderBottom="1px solid"
                      borderColor={grey[200]}
                    >
                      {item[config.icon] ? (
                        <Icon sx={{ fontSize: "16px" }}>
                          {item[config.icon]}
                        </Icon>
                      ) : null}
                      <Box fontSize="14px">{item[config.text]}</Box>
                    </Box>
                  ) : (
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="row"
                      gap={1}
                      alignItems="center"
                      padding="8px"
                      borderBottom="1px solid"
                      borderColor={grey[200]}
                    >
                      <Checkbox
                        checked={selected.indexOf(item[config.id]) !== -1} sx={{padding: 0}}
                      />
                      {item[config.icon] ? (
                        <Icon sx={{ fontSize: "16px" }}>
                          {item[config.icon]}
                        </Icon>
                      ) : null}
                      <Box fontSize="14px">{item[config.text]}</Box>
                    </Box>
                  )}
                </MenuItem>
              );
            })}
          </Box>
          {multiple ? (
            <Box width="100%">
              <Button onClick={clearSelected} fullWidth color="warning">
                Clear
              </Button>
            </Box>
          ) : null}
        </Box>
      </Menu>
    </>
  );
};

export default FilterOption;
