export const constant = {
  headerHeight: "72px",
  sideBarWidth: "256px",
  sideBarPaddingY: "22px",
  sideBarPaddingX: "24px",
  sideBarWidthSm: "80px",
  sideBarPaddingXSm: "20px",
  sideBarTransition: "all 0.25s ease-in-out",
  tableRowHeight: 50,
  tableHeaderHeight: 56,
};
