import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Guard from "../../components/Guard/Guard";
import HomeLayout from "../../layouts/HomeLayout/HomeLayout";
// import { loading } from "../../store/actions/loadingAction";

export default function HomePage() {
  const {loading} = useSelector((state) => state);

  useEffect(() => {    
      console.log(loading);    
  }, [loading]);

  return (
    <Guard>
      <HomeLayout>
        <Outlet></Outlet>
      </HomeLayout>
      <Backdrop
        sx={{ color: "#fff", zIndex: 99999 }}
        open={loading.value === 'show'}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Guard>
  );
}
