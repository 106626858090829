import { deleteToken } from "../../services/auth";

const initialState = {  
  role: null,
  email: null,
  firstname: null,
  lastname: null,
  phone: null,  
  username: null,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "login":
      return Object.assign(state, {        
        email: payload?.email || null,
        firstname: payload?.firstname || null,
        lastname: payload?.lastname || null,
        phone: payload?.phone || null,
        role: payload?.role || null,
        username: payload?.username || null,
      });
    case "logout":
      deleteToken();
      return Object.assign(state, {        
        email: null,
        firstname: null,
        lastname: null,
        phone: null,
        role: null,
        username: null,
      });
    default:
      return state;
  }
};
export default authReducer;
