export const loading = () => {
  return {
    type: "loading",
  };
};

export const stoploading = () => {
  return {
    type: "stoploading",
  };
};
