import { CircularProgress, Icon, IconButton } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import PageSubTitle from "../../../components/PageSubTitle/PageSubTitle";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { httpErrorHandling, httpGet } from "../../../services/http";

const ClinicDashboard = () => {
  const [error, setError] = useState({ active: false, notlinked: false });
  const [loading, setLoading] = useState({ active: false, notlinked: false });

  const [activeClinic, setActiveClinic] = useState(0);
  const [notlinkedClinic, setNotlinkedClinic] = useState(0);

  useEffect(() => {
    init();
  }, []);

  function init() {
    getActiveClinics();
    getNotLinkedClinics();
  }

  const getActiveClinics = async () => {
    try {
      setError((state) => ({ ...state, active: false }));
      setLoading((state) => ({ ...state, active: true }));
      const result = await httpGet("dashboard/clinic/active", {});
      setLoading((state) => ({ ...state, active: false }));
      if (result.data) {
        setActiveClinic(result.data.total);
      } else {
        throw "no data";
      }
    } catch (e) {      
      httpErrorHandling(e);
      setLoading((state) => ({ ...state, active: false }));
      setError((state) => ({ ...state, active: true }));
    }
  };

  const getNotLinkedClinics = async () => {
    try {
      setError((state) => ({ ...state, notlinked: false }));
      setLoading((state) => ({ ...state, notlinked: true }));
      const result = await httpGet("dashboard/clinic/notlinked", {});
      setLoading((state) => ({ ...state, notlinked: false }));
      if (result.data) {
        setNotlinkedClinic(result.data.total);
      } else {
        throw "no data";
      }
    } catch (e) {
      httpErrorHandling(e);
      setLoading((state) => ({ ...state, notlinked: false }));
      setError((state) => ({ ...state, notlinked: true }));
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="24px"
      border={"1px solid " + grey[200]}
      borderRadius="8px"
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <PageTitle>Clinics</PageTitle>
        <IconButton title="Refresh data" onClick={init}>
          <Icon
            sx={{ fontSize: "30px" }}
            className={loading.active || loading.notlinked ? "spining" : null}
          >
            refresh
          </Icon>
        </IconButton>
      </Box>
      <Box display="flex" flexDirection="row" marginTop="24px" gap={3}>
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="8px"
          width="100%"
          border={"1px solid " + grey[200]}
          padding="18px"
        >
          <Box display="flex" flexDirection="row" gap={2} alignItems="center">
            <Box
              borderRadius="50%"
              height="40px"
              width="40px"
              backgroundColor="#2F80ED14"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Icon color="primary">equalizer</Icon>
            </Box>
            <PageTitle fontSize="18px">Active</PageTitle>
          </Box>
          {loading.active === false && error.active === false ? (
            <Box
              display="flex"
              flexDirection="column"
              height="120px"
              alignItems="center"
            >
              <PageTitle fontSize="48px">{activeClinic}</PageTitle>
              <PageSubTitle>Clinics are linked</PageSubTitle>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              height="120px"
              justifyContent="center"
              alignItems="center"
            >
              {loading.active === true ? (
                <CircularProgress />
              ) : (
                <Box>
                  <IconButton onClick={getActiveClinics} title="Reload Data">
                    <Icon sx={{ fontSize: "40px" }}>refresh</Icon>
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="8px"
          width="100%"
          border={"1px solid " + grey[200]}
          padding="18px"
        >
          <Box display="flex" flexDirection="row" gap={2} alignItems="center">
            <Box
              borderRadius="50%"
              height="40px"
              width="40px"
              backgroundColor="#2F80ED14"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Icon color="primary">equalizer</Icon>
            </Box>
            <PageTitle fontSize="18px">Not Linked</PageTitle>
          </Box>
          {loading.notlinked === false && error.notlinked === false ? (
            <Box
              display="flex"
              flexDirection="column"
              height="120px"
              alignItems="center"
            >
              <PageTitle fontSize="48px">{notlinkedClinic}</PageTitle>
              <PageSubTitle>Clinics haven't been linked</PageSubTitle>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              height="120px"
              justifyContent="center"
              alignItems="center"
            >
              {loading.notlinked === true ? (
                <CircularProgress />
              ) : (
                <Box>
                  <IconButton onClick={getNotLinkedClinics} title="Reload Data">
                    <Icon sx={{ fontSize: "40px" }}>refresh</Icon>
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ClinicDashboard;
