import "./App.scss";

import MainRouter from "./routes/MainRouter";
import { ThemeProvider } from "@mui/material";
import { getToken } from "./services/auth";
import storage from "./services/storage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loggedIn } from "./store/actions/appAction";
import { login } from "./store/actions/authAction";
import theme from "./config/theme";
import EventBus from "./common/EventBus";

function App() {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    init();    
  }, [auth]);

  const init = () => {
    const token = getToken();
    if (token) {
      const user = storage.get("user");
      if (user) {
        dispatch(login(user));        
      } else {
        EventBus.$dispatch('app.logout');
      }      
    } else {
      EventBus.$dispatch('app.logout');
    }    
  };

  return (    
    <ThemeProvider theme={theme}>
      <MainRouter></MainRouter>
    </ThemeProvider>
  );
}

export default App;
