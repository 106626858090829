import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ClinicPage from "../pages/ClinicPage/ClinicPage";
import MasterData from "../pages/ConfigPage/MasterData";
import DashboardPage from "../pages/DashboardPage/DashboardPage";
import EmployeePage from "../pages/EmployeePage/EmployeePage";
import EmployeeReviewPage from "../pages/EmployeeReviewPage/EmployeeReviewPage";
import HomePage from "../pages/HomePage/HomePage";
import LoginPage from "../pages/LoginPage/LoginPage";
import LogoutPage from "../pages/LogoutPage/LogoutPage";
import SyncHistory from "../pages/SyncHistory/SyncHistory";
import SyncNow from "../pages/SyncNow/SyncNow";
import UsersPage from "../pages/UsersPage/UsersPage";

const MainRouter = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/*" element={<HomePage />}>
            <Route path="dashboard" element={<DashboardPage />}></Route>
            <Route path="clinics" element={<ClinicPage />}></Route> 
            <Route path="employees" element={<EmployeePage />}></Route>   
            <Route path="employees-review" element={<EmployeeReviewPage />}></Route>    
            <Route path="syncnow" element={<SyncNow />}></Route>     
            <Route path="synchistory" element={<SyncHistory />}></Route>     
            <Route path="master-data" element={<MasterData />}></Route>     
            <Route path="users" element={<UsersPage />}></Route>     
            <Route path="*" element={<Navigate to="/dashboard" replace />} />               
          </Route>
          <Route path="/logout" element={<LogoutPage />}></Route>  
          <Route path="*" element={<LoginPage />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default MainRouter;
