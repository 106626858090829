import React, { useState } from "react";
import Slide from "@mui/material/Slide";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  useMediaQuery,
} from "@mui/material";
import "./UserForm.scss";
import { Controller, useForm } from "react-hook-form";
import {
  httpErrorHandling,
  httpGet,
  httpPost,
  httpPostFormData,
} from "../../services/http";
import { SnackContext } from "../Main/Main";
import text from "../../config/text";
import { useDispatch } from "react-redux";
import { loading, stoploading } from "../../store/actions/loadingAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const formstate = {
  email: "",
  username: "",
  role: "",
  phone: "",
  firstname: "",
  lastname: "",
  mode: "new",
  password: "",
  confirmpassword: "",
};

function UserForm(props) {
  const { onClose, selectedValue, open, id } = props;

  const handleClose = () => {
    reset();
    onClose(selectedValue);
  };

  const [maxWidth, setMaxWidth] = React.useState("sm");

  const [state, setState] = useState(formstate);

  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const snack = React.useContext(SnackContext);

  const roles = [
    { id: "admin", text: "Admin" },
    { id: "super_admin", text: "Superadmin" },
  ];

  const dispatch = useDispatch();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm({
    defaultValues: state,
  });

  const submit = async (data) => {
    try {
      dispatch(loading());
      delete data.mode;
      delete data.confirmpassword;
      const result = await httpPost("user/create", data);
      if (result && result.data.success) {
        snack.setSnack((prev) => ({
          ...prev,
          open: true,
          variant: "success",
          message: "User added",
        }));
        reset();
      } else {
        snack.setSnack((prev) => ({
          ...prev,
          open: true,
          variant: "warning",
          message: result?.detail || text.tryAgain,
        }));
      }
      dispatch(stoploading());
    } catch (err) {
      dispatch(stoploading());
      const error = httpErrorHandling(err);
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        variant: "warning",
        message: error?.detail || text.tryAgain,
      }));
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="dialog-slide"
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      fullWidth={true}
    >
      <DialogTitle className="modal-title">
        {state.mode == "new" ? "Add" : "Edit"} User{" "}
        <Box
          title="Close"
          position="absolute"
          top="20px"
          right="12px"
          sx={{ cursor: "pointer" }}
          onClick={handleClose}
        >
          <Icon>close</Icon>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box paddingTop={4}>
          <form id="user-form">
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="user-username">Username</InputLabel>
                  <Controller
                    name="username"
                    control={control}
                    render={({ field }) => (
                      <OutlinedInput
                        id="user-username"
                        label="Username"
                        variant="standard"
                        {...field}
                        {...register("username", {
                          required: true,
                          validate: (val) => {
                            if (!val.match(/^[A-Za-z0-9]+$/)) {
                              return "Only character and number, no space";
                            }
                          },
                        })}
                        error={errors.username != null}
                      />
                    )}
                  />
                  {errors.username != null && (
                    <FormHelperText className="error-text">                      
                      {errors.username?.type === "required"
                        ? "Username is required"
                        : errors.username?.type === "validate"
                        ? errors.username.message
                        : ""}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="user-role">Role</InputLabel>
                  <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                      <Select
                        label="Role"
                        id="user-role"
                        name="role"
                        {...field}
                        defaultValue={"admin"}
                        {...register("role", { required: true })}
                      >
                        {roles.map((p, k) => {
                          return (
                            <MenuItem value={p.id} key={k}>
                              {p.text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                  {errors.role ? (
                    <FormHelperText className="error-text">
                      {errors.role?.type === "required"
                        ? "Role is required"
                        : ""}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="user-firstname">First Name</InputLabel>
                  <Controller
                    name="firstname"
                    control={control}
                    render={({ field }) => (
                      <OutlinedInput
                        id="user-firstname"
                        label="First Name"
                        variant="standard"
                        {...field}
                        {...register("firstname", { required: true })}
                        error={errors.firstname != null}
                      />
                    )}
                  />
                  {errors.firstname != null && (
                    <FormHelperText className="error-text">
                      {errors.firstname?.type === "required"
                        ? "First name is required"
                        : ""}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="user-lastname">Last Name</InputLabel>
                  <Controller
                    name="lastname"
                    control={control}
                    render={({ field }) => (
                      <OutlinedInput
                        id="user-lastname"
                        label="First Name"
                        variant="standard"
                        {...field}
                        {...register("lastname", { required: true })}
                        error={errors.lastname != null}
                      />
                    )}
                  />
                  {errors.lastname != null && (
                    <FormHelperText className="error-text">
                      {errors.lastname?.type === "required"
                        ? "Last Name is required"
                        : ""}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="user-email">Email</InputLabel>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <OutlinedInput
                        id="user-email"
                        label="Email"
                        variant="standard"
                        {...field}
                        {...register("email", { required: true })}
                        error={errors.email != null}
                      />
                    )}
                  />
                  {errors.email != null && (
                    <FormHelperText className="error-text">
                      {errors.email?.type === "required"
                        ? "Email is required"
                        : ""}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="user-email">Phone</InputLabel>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <OutlinedInput
                        id="user-phone"
                        label="Phone"
                        variant="standard"
                        {...field}
                        {...register("phone")}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="user-password">Password</InputLabel>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <OutlinedInput
                        id="user-password"
                        fullWidth
                        label="Password"
                        variant="standard"
                        name="password"
                        type="password"
                        {...field}
                        {...register("password", {
                          required: true,
                        })}
                        error={errors.password != null}
                      />
                    )}
                  />
                  {errors.password != null && (
                    <FormHelperText className="error-text">
                      {errors.password?.type === "required"
                        ? "Password is required"
                        : ""}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="user-confirm">
                    Confirm Password
                  </InputLabel>
                  <Controller
                    name="confirmpassword"
                    control={control}
                    render={({ field }) => (
                      <OutlinedInput
                        id="user-confirm"
                        fullWidth
                        variant="standard"
                        name="confirmpassword"
                        label="Confirm Password"
                        type="password"
                        {...field}
                        {...register("confirmpassword", {
                          required: true,
                          validate: (val) => {
                            if (watch("password") != val) {
                              return "Password is not matched";
                            }
                          },
                        })}
                        error={errors.confirmpassword != null}
                      />
                    )}
                  />
                  {errors.confirmpassword != null && (
                    <FormHelperText className="error-text">
                      {errors.confirmpassword.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="warning">
          Cancel
        </Button>
        <Button onClick={handleSubmit(submit)} autoFocus variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserForm;
