import { grey } from "@mui/material/colors";

const PageTitle = ({children, fontSize = "24px"}) =>{
    const titleStyle = {
        fontSize: fontSize,
        padding: "5px 0",
        color: grey[900],
        fontWeight: 600
    }
    
    return (
        <div style={titleStyle}>
            {children}
        </div>
    )
}


export default PageTitle;