import {
  alpha,
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import PageTitle from "../../components/PageTitle/PageTitle";
import { DataGrid } from "@mui/x-data-grid";
import {
  httpErrorHandling,
  httpGet,
  httpPost,
  httpPut,
} from "../../services/http";
import React, { useCallback, useEffect, useState } from "react";
import "./EmployeePage.scss";
import UserForm from "../../components/UserForm/UserForm";
import { useDispatch, useSelector } from "react-redux";
import pagination from "../../services/pagination";
import { PromptContext, SnackContext } from "../../components/Main/Main";
import text from "../../config/text";
import datetime from "../../services/datetime";
import { green, grey } from "@mui/material/colors";
import debounce from "lodash.debounce";
import FilterOption from "../../components/FilterOption/FilterOption";
import { constant } from "../../config/constant";

const EmployeePage = () => {
  const columns = [
    {
      field: "__check__",
      headerClassName: "table-data-header table-data-header-checkbox",
      width: 50,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "dayforceEmployeeId",
      headerName: "ID",
      minWidth: 80,
      flex: 0.5,
      headerClassName: "table-data-header",
      disableColumnMenu: true,      
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 120,
      flex: 1.1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      renderCell: (param) => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={1}
            width="100%"
          >
            {param.row.syncEnabled === true ? (
              <Icon
                color="success"
                title="Sync Enabled"
                sx={{ fontSize: "20px" }}
              >
                play_circle_outline
              </Icon>
            ) : (
              <Icon
                title="Sync Disabled"
                sx={{ fontSize: "20px" }}
                color="warning"
              >
                pause_circle_outline
              </Icon>
            )}
            <Box>{param.row.name}</Box>
          </Box>
        );
      },
    },
    {
      field: "zenotiEmployeeName",
      headerName: "Zenoti Employee",
      minWidth: 120,
      flex: 1.1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      sortable: false,
      filter: false,
      renderCell: (param) => {
        return (
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
          >
            {param.row.edit === false ? (
              <>
                <Box
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {param.row.zenotiEmployeeName}
                </Box>
                <IconButton onClick={() => handleEditMode(param.row)}>
                  <Icon sx={{ fontSize: "16px" }}>edit_outline</Icon>
                </IconButton>
              </>
            ) : (
              <Autocomplete
                id={"employee-option-" + param.row.index}
                sx={{
                  "& input::placeholder": {
                    fontSize: "13px",
                  },
                  input: {
                    fontSize: "13px",
                  },
                }}
                autoHighlight
                fullWidth
                open={param.row.openOption}
                onOpen={() => {
                  setOpenOption(param.row, true);
                }}
                onClose={() => {
                  setOpenOption(param.row, false);
                }}
                // isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                options={zenotiEmployees}
                size="small"
                onBlur={() => handleEditMode(param.row, "close")}
                onChange={(event, newValue) => {
                  changeEmployee(newValue, param.row);
                }}
                loading={loadingEmployeeOption}
                // filterOptions={(x) => filterZenotiEmployee(x)}
                // inputValue={inputValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search Employee"
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: "new-password",
                      endAdornment: (
                        <React.Fragment>
                          {loadingEmployeeOption ? (
                            <CircularProgress color="inherit" size={14} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      disableUnderline: false,
                    }}
                    onKeyDown={(ev) => ev.stopPropagation()}
                    onKeyUp={(ev) => ev.stopPropagation()}
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ fontSize: "12px" }} {...props}>
                    {option.name}
                  </Box>
                )}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "clinics",
      headerName: "Dayforce Clinics",
      minWidth: 120,
      flex: 1.5,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      renderCell: (param) => {
        return ClinicRow(param.row.clinics);
      },
    },
    {
      field: "lastSynced",
      headerName: "Last Modified",
      minWidth: 72,
      flex: 1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 80,
      flex: 1,
      headerClassName: "table-data-header",
      disableColumnMenu: true,
      renderCell: (param) => {
        return statusRow(param.row.status);
      },
    },

    /* {
        field: "option",
        headerName: "Sync",
        width: 72,
        flex: 0,
        headerClassName: "table-data-header",
        sortable: false,
        filter: false,
        disableColumnMenu: true,
        renderCell: (param) => {
          return (
            <Box>
              <Switch
                size="small"
                checked={param.row.syncEnabled}
                inputProps={{ "aria-label": "controlled" }}
                onChange={(row) => switchSync(param)}
              />
            </Box>
          );
        },
      }, */
  ];

  const [rows, setRows] = useState([]);
  const [role, setRole] = useState(false);
  const user = useSelector((state) => state.auth);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCountState, setRowCountState] = React.useState(10);
  const [error, setError] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const snack = React.useContext(SnackContext);
  const dispatch = useDispatch();
  const [loadingData, setLoadingData] = useState(false);
  const [sortModel, setSortModel] = React.useState([
    { field: "name", sort: "asc" },
  ]);
  const [sorting, setSorting] = useState("Employee");
  const [searchField, setSearchField] = useState("");
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("Active");
  const statuses = [
    { id: "All", text: "All", icon: "" },
    { id: "New", text: "New", icon: "star_outline" },
    { id: "Linked", text: "Linked", icon: "check" },
    { id: "Unlinked", text: "Unlinked", icon: "close" },
    { id: "MatchFound", text: "Match Found", icon: "check" },
    { id: "NoMatchFound", text: "No Match Found", icon: "close" },
    { id: "ZenotiFailedToCreate", text: "Zenoti Failed", icon: "close" },
    { id: "PrimaryClinicNotLinked", text: "Primary Not Linked", icon: "close" },
    { id: "Terminated", text: "Terminated", icon: "close" },
  ];
  const [statusObject, setStatusObject] = useState({});
  const [selectedRow, setSelectedRow] = useState([]);

  const [clinics, setClinics] = useState([]);

  const [errors, setErrors] = useState({ clinics: false, employee: false });
  const [loading, setLoading] = useState({ clinics: false, sync: false });

  const [clinicId, setClinicId] = useState([]);

  const [zenotiEmployees, setZenotiEmployees] = useState([]);
  const prompt = React.useContext(PromptContext);
  const loadingEmployeeOption = zenotiEmployees.length === 0;

  useEffect(() => {
    getData();
  }, [page, sorting]);

  useEffect(() => {
    if (page !== 0) setPage(0);
    else getData();
  }, [pageSize, status, searchField]);

  useEffect(() => {
    if (user.role === "super_admin") {
      setRole(true);
    }
    const obj = {};
    statuses.forEach((status) => {
      obj[status.id] = { text: status.text, icon: status.icon };
    });
    setStatusObject(obj);
    getClinics();
    getZenotiEmployee();
  }, []);

  const getData = async () => {
    try {
      setError(false);
      setLoadingData(true);

      const filters = {};
      if (searchField) {
        filters.name = searchField;
      }

      if (status && status !== "All") {
        filters.status = status;
      }

      if (clinicId.length) {
        filters.clinics = clinicId;
      }

      const body = {
        ...pagination.paginate(page, pageSize),
        filters: filters,
        order_by: sorting,
      };
      const res = await httpPost("employee/list", body);
      const temp = [];
      if (res && res.data) {
        res.data.data.forEach((el, i) => {
          temp.push({
            index: i,
            id: el.employeeId,
            name: el.name,
            dayforceEmployeeId: el.dayforceEmployeeId,
            zenotiEmployeeId: el.zenotiEmployeeId,
            status: el.status,
            lastSynced: datetime.utcToLocal(el.lastSynced),
            cellClassName: "table-data-cell",
            disableSelectionOnClick: true,
            syncEnabled: el.syncEnabled,
            clinics: el.clinics,
            zenotiEmployeeName: el.zenotiEmployeeName,
            edit: false,
            openOption: false,
          });
        });
      }
      setRows(temp);
      setRowCountState(res.data.total);
      setLoadingData(false);
    } catch (err) {
      setError(true);
      setLoadingData(false);
      const error = httpErrorHandling(err);
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        variant: "warning",
        message: "Data cannot be retrieved at the moment, please try again.",
      }));
    }
  };

  /*
      clinic_name_asc = "Clinic"
      clinic_name_desc = "!Clinic"
      ledger_code_asc = "LedgerCode"
      ledger_code_desc = "!LedgerCode"
      last_synced_asc = "LastSynced"
      last_synced_desc = "!LastSynced"
      status_asc = "Status"
      status_desc = "!Status"
    */

  const handleSortModelChange = async (newSort) => {
    await setSortModel(newSort);
    if (newSort?.length) {
      let sort = "";
      switch (newSort[0].field) {
        case "name":
          sort = "Employee";
          break;
        case "lastSynced":
          sort = "LastSynced";
          break;
        case "status":
          sort = "Status";
          break;
        case "dayforceEmployeeId":
          sort = "ID";
          break;
        default:
          sort = "Employee";
          setSortModel([{ field: "employee", sort: "asc" }]);
      }
      if (newSort[0].sort === "desc") {
        sort = "!" + sort;
      }
      setSorting(sort);
    }
  };

  const handleSearch = useCallback((el) => {
    setSearchText(el.target.value);
    searchCb(el);
  });

  const searchCb = useCallback(
    debounce((el) => {
      setSearchField(el.target.value);
    }, 700)
  );

  const clearSearch = () => {
    setSearchField("");
    setSearchText("");
  };

  const changeStatus = (status) => {
    setStatus(status);
  };

  const changeClinic = (id) => {
    setClinicId(id);
  };

  const switchSync = async (param) => {
    if (!selectedRow.length) {
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        variant: "warning",
        message: "Please select Employee",
      }));
      return;
    }
    let ans = await prompt.openPrompt(
      "Confirm",
      `Do you want to ${
        param ? "Turn On " : "Turn Off "
      } sync for these employees?`
    );
    if (!ans) return;
    const payload = { syncEnabled: param, employeeIds: [] };

    selectedRow.forEach((row) => {
      payload.employeeIds.push(row);
    });

    setLoadingData(true);
    try {
      const result = await httpPost("employee/sync", payload);
      setLoadingData(false);
      if (result && result.data.success) {
        snack.setSnack((prev) => ({
          ...prev,
          open: true,
          variant: "success",
          message: "Updated",
        }));
        setSelectedRow([]);
        getData();
        // param.api.updateRows([
        //   { ...param.row, id: param.id, syncEnabled: !param.row.syncEnabled },
        // ]);
      } else {
        snack.setSnack((prev) => ({
          ...prev,
          open: true,
          variant: "warning",
          message: result?.detail || text.tryAgain,
        }));
      }
    } catch (err) {
      setLoadingData(false);
      const e = httpErrorHandling(err);
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        variant: "warning",
        message: text.tryAgain,
      }));
    }
  };

  const noRows = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Box display="flex" justifyContent="center" zIndex={99}>
        <h4 style={{ color: grey[600] }}>No Result Found</h4>
        <IconButton onClick={() => getData()} title="Reload">
          <Icon>refresh</Icon>
        </IconButton>
      </Box>
    </Box>
  );

  const statusRow = (status) => {
    return (
      <Stack direction="row">
        <Chip
          sx={{
            display: status === "Linked" ? "flex" : "none",
            flexDirection: "row",
            alignContent: "center",
            fontSize: "11px",
            color: green[800],
            bgcolor: alpha(green[100], 0.5),
          }}
          icon={<Icon color={green[800]}>{statusObject[status].icon}</Icon>}
          label={statusObject[status].text}
          variant="contained"
          size="small"
        ></Chip>
        <Chip
          sx={{
            display: status !== "Linked" ? "flex" : "none",
            flexDirection: "row",
            alignContent: "center",
            fontSize: "11px",
            color: grey[700],
            bgcolor: alpha(grey[100], 0.9),
          }}
          icon={<Icon color={grey[700]}>{statusObject[status].icon}</Icon>}
          label={statusObject[status].text}
          variant="contained"
          size="small"
        />
      </Stack>
    );
  };

  const ClinicRow = (clinics) => {
    if (clinics) {
      return (
        <Box>
          {clinics.length ? (
            <>
              {clinics[0].name}
              <span>
                {clinics.length > 1 ? (
                  <Tooltip
                    placement="top"
                    title={
                      <React.Fragment>
                        {clinics.map((c, i) => {
                          return (
                            <span key={i}>
                              {i > 0 ? (
                                <span>
                                  {c.name} <br />
                                </span>
                              ) : null}
                            </span>
                          );
                        })}
                      </React.Fragment>
                    }
                    arrow
                  >
                    <Box
                      component="span"
                      sx={{
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {" +"}
                      {clinics.length - 1} {"other clinics"}
                    </Box>
                  </Tooltip>
                ) : null}
              </span>
            </>
          ) : null}
        </Box>
      );
    } else return null;
  };

  const getClinics = async () => {
    try {
      setErrors((e) => ({ ...e, clinics: false }));
      setLoading((l) => ({ ...l, clinics: true }));
      const res = await httpPost("clinic/option");
      if (res.data) {
        setClinics(
          res.data.map((d) => ({ id: d.dayforce_clinic_id, text: d.name }))
        );
      }
      setLoading((l) => ({ ...l, clinics: false }));
    } catch (e) {
      httpErrorHandling(e);
      setErrors((e) => ({ ...e, clinics: true }));
      setLoading((l) => ({ ...l, clinics: false }));
    }
  };

  const closeClinicFilter = (params) => {    
    if (params === true) {
      getData();
    }
  };

  const onErrorClinicFilter = (detail) => {
    if (detail === "Empty") {
      getClinics();
    }
  };

  const handleEditMode = (param, act = "open") => {
    if (errors.employee === true) {
      getZenotiEmployee();
    }
    setRows((prev) => {
      const newRows = [...prev];
      if (act === "open") newRows[param.index].edit = true;
      else newRows[param.index].edit = false;
      return newRows;
    });
    if (act === "open") {
      setTimeout(() => {
        document.getElementById("employee-option-" + param.index).focus();
      }, 100);
    }
  };
  const setOpenOption = (param, val) => {
    setRows((prev) => {
      const newRows = [...prev];
      if (val === true) newRows[param.index].openOption = true;
      else newRows[param.index].openOption = false;
      return newRows;
    });
  };

  const getZenotiEmployee = async () => {
    try {
      const payload = { keyword: "" };
      setErrors((e) => ({ ...e, employee: false }));
      httpPost("employee/zenoti/option", payload).then((result) => {
        setZenotiEmployees(
          result.data.map((d) => ({
            id: d.id,
            name: d.first_name + " " + d.last_name,
          }))
        );
      });
    } catch (e) {
      console.log(e);
      httpErrorHandling(e);
      setErrors((e) => ({ ...e, employee: true }));
    }
  };

  const changeEmployee = async (value, param) => {
    let ans = await prompt.openPrompt(
      "Confirm",
      `Do you want to link ${param.name} to ${value.name}?`
    );
    if (!ans) return;
    const payload = {
      employeeId: param.id,
      zenotiEmployeeId: value.id,
    };
    try {
      setLoadingData(true);
      const result = await httpPut("employee/zenoti/change", payload);
      if (result.data && result.data.success) {
        snack.setSnack((prev) => ({
          ...prev,
          open: true,
          message: "Link successfuly updated.",
          variant: "success",
        }));
        getData();
      }
      setLoadingData(false);
    } catch (err) {
      setLoadingData(false);
      const e = httpErrorHandling(err);
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        message: "Please try again.",
        variant: "warning",
      }));
    }
  };

  const syncNow = async () => {
    try {
      setLoading((l) => ({ ...l, sync: true }));
      const res = await httpPost("lambda/employee/sync");
      console.log(res);
      if (res.status === 200) {
        snack.setSnack((prev) => ({
          ...prev,
          open: true,
          message: "Employee sync has been started successfully.",
          variant: "success",
        }));
      } else {
        throw "not success";
      }
      setLoading((l) => ({ ...l, sync: false }));
    } catch (e) {
      httpErrorHandling(e);
      snack.setSnack((prev) => ({
        ...prev,
        open: true,
        message: "Please try again.",
        variant: "warning",
      }));
      setLoading((l) => ({ ...l, sync: false }));
    }
  };

  return (
    <Box component="div" display="flex" flexDirection="column" gap={2}>
      <PageTitle>Manage Employee</PageTitle>
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        width="100%"
        alignContent="center"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="row" gap={1}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel htmlFor="clinic-filter-search">Search</InputLabel>
            <OutlinedInput
              id="clinic-filter-search"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    disabled={searchField.length <= 0}
                    onClick={clearSearch}
                  >
                    <Icon
                      sx={{
                        color:
                          searchField.length <= 0 ? "transparent" : "inherit",
                      }}
                    >
                      close
                    </Icon>
                  </IconButton>
                </InputAdornment>
              }
              onChange={handleSearch}
              label="Search"
              value={searchText}
            />
          </FormControl>
        </Box>
        <Box></Box>
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          gap={1}
        >
          {/* <FormControl size="small" sx={{ width: 216 }}>
              <InputLabel id="clinic-filter-status">Status</InputLabel>
              <Select
                labelId="clinic-filter-status"
                id="clinic-filter-status"
                label="Status"
                variant="outlined"
                autoWidth
                value={status}
                onChange={changeStatus}
              >
                {Object.keys(statuses).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {statuses[key]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>           */}
          <FilterOption
            selected={clinicId}
            items={clinics}
            onSelectItem={changeClinic}
            label={
              loading.clinics
                ? "Loading..."
                : "Clinics " + (clinicId.length ? ` (${clinicId.length})` : "")
            }
            onClose={(param) => closeClinicFilter(param)}
            multiple={true}
            disabled={loading.clinics || loadingData}
            onError={onErrorClinicFilter}
            width="120px"
          ></FilterOption>
          {/* <FilterOption
            selected={status}
            items={statuses}
            onSelectItem={changeStatus}
            disabled={loadingData}
          ></FilterOption> */}
          <Button
            startIcon={<Icon sx={{ fontSize: "14px" }}>sync</Icon>}
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            color="grey"
            size="small"
            onClick={() => switchSync(true)}
            disabled={loadingData}
          >
            Turn On
          </Button>
          <Button
            startIcon={<Icon sx={{ fontSize: "14px" }}>link</Icon>}
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            color="grey"
            size="small"
            onClick={() => switchSync(false)}
            disabled={loadingData}
          >
            Turn Off
          </Button>
          <Button
            startIcon={
              <Icon
                className={loading.sync ? "spining" : ""}
                sx={{ fontSize: "14px" }}
              >
                sync
              </Icon>
            }
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            color="grey"
            size="small"
            onClick={syncNow}
            disabled={loadingData || user.role != "super_admin" || loading.sync}
          >
            Refresh
          </Button>
        </Box>
      </Box>
      <div className="table-data-container">
        <Box style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              getRowId={(row) => row.id}
              rows={rows}
              columns={columns}
              loading={loadingData}
              rowHeight={constant.tableRowHeight}
              headerHeight={constant.tableHeaderHeight}
              disableSelectionOnClick
              page={page}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50]}
              pagination
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowCount={rowCountState}
              sortingMode="server"
              sortingOrder={["desc", "asc"]}
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              disableColumnFilter
              components={{
                NoResultsOverlay: noRows,
              }}
              checkboxSelection
              onSelectionModelChange={setSelectedRow}
              selectionModel={selectedRow}
            />
          </div>
        </Box>
      </div>
    </Box>
  );
};

export default EmployeePage;
