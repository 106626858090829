import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import EventBus from "../../common/EventBus";
import { getToken } from "../../services/auth";
import storage from "../../services/storage";
import { login } from "../../store/actions/authAction";

class Guard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: true,
      token: true,
    };
    this.checkSession = this.checkSession.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.checkSession();
    EventBus.$on("app.logout", () => {
      this.logout();
    });
    EventBus.$on("app.login", () => {
      this.checkSession();
    });
  }

  componentWillUnmount() {
    EventBus.$remove("app.login");
    EventBus.$remove("app.logout");
  }

  logout() {
    this.props.login({});
    this.setState((state) => ({ ...state, loggedIn: false }));    
  }

  checkSession() {
    const token = getToken();    
    if (token) {
      const user = storage.get("user");
      this.setState({ loggedIn: true, token: true });
    } else {
      this.setState({ loggedIn: false, token: false });
    }
  }

  render() {
    return (
      <>
        {this.state.loggedIn === true && this.state.token === true ? (
          this.props.children
        ) : this.state.loggedIn === false && this.state.token === true ? (
          <Navigate to={"/logout"}></Navigate>
        ) : (
          <Navigate to={"/login"}></Navigate>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload) => dispatch(login(payload)),
    // loggedIn: (payload) => dispatch(loggedIn(payload)),
  };
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, mapDispatchToProps)(Guard);
