import { Alert, Icon, IconButton, Slide, Snackbar } from "@mui/material";
import React from "react";
import { Provider } from "react-redux";
import { compose, legacy_createStore } from "redux";
import allReducers from "../../store";

import PromptAlert from "../PromptAlert/PromptAlert";

const Main = ({ children }) => {
  const [snack, setSnack] = React.useState({
    open: false,
    duration: 2000,
    vertical: "top",
    horizontal: "center",
    message: "",
    variant: "info",
  });

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack((prev) => ({ ...prev, open: false }));
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }

  const [prompt, setPrompt] = React.useState({
    message: "",
    open: false,
    title: "",
    promise: null,
  });

  const openPrompt = async (title, message) => {
    let promise = new Promise((resolve) => {
      setPrompt((prev) => ({
        ...prev,
        promise: resolve,
        message: message,
        open: true,
        title: title,
      }));
    });
    return promise;
  };

  const closePrompt = async (ans) => {
    setPrompt((prev) => ({
      ...prev,
      open: false,
    }));
    prompt.promise(ans);
  };

  const actionSnack = (
    <React.Fragment>
      {/* <Button color='secondary' size='small' onClick={handleClose}>
            UNDO
          </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackClose}
      >
        <Icon>cancel</Icon>
      </IconButton>
    </React.Fragment>
  );

  const store = legacy_createStore(allReducers);

  return (
    <>
      <Provider store={store}>
        <PromptContext.Provider value={{ prompt, openPrompt }}>
          <SnackContext.Provider value={{ snack, setSnack }}>
            <div>{children}</div>
            <Snackbar
              TransitionComponent={SlideTransition}
              open={snack.open}
              anchorOrigin={{
                vertical: snack.vertical,
                horizontal: snack.horizontal,
              }}
              autoHideDuration={snack.duration}
              onClose={handleSnackClose}
            >
              <Alert severity={snack.variant} onClose={handleSnackClose}>
                {snack.message}
              </Alert>
            </Snackbar>
            <PromptAlert
              open={prompt.open}
              handleClose={closePrompt}
              title={prompt.title}
              message={prompt.message}
            ></PromptAlert>
          </SnackContext.Provider>
        </PromptContext.Provider>
      </Provider>
    </>
  );
};

export const SnackContext = React.createContext();

export const PromptContext = React.createContext();

export default Main;
